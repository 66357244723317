import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const PrivateRoute = ({ children }) => {
  const { dataUser } = useContext(UserContext);
  // const { pathname, search } = useLocation();

  // const lastPath = pathname + search;
  // localStorage.setItem('lastPath', lastPath );
  // console.log('PrivateRoute user.id',user.id);
  // alert(JSON.stringify(dataUser))
  return dataUser.email ? <Navigate to="/catalog/catalogo" /> : children;
};

export default PrivateRoute;
