import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { Sidebar } from '../Sidebar';
import Navbar1 from '../Navbar1';
import Footer from '../../Footer';

import foto from '../hora.png';

const MainPage = ({ children }) => (
  <>
    <Sidebar />
    <div className="grand_conteiner">
      <div className="topbar">
        <Navbar1 />
      </div>
      <div className="page">{children}</div>
      {/* <FloatingWhatsApp
                        phoneNumber="+543462414148"
                        message="¡Hola! Estoy interesado en tus servicios."
                        accountName='Soporte'
                        statusMessage='Tiempo de respuesta aproximadamente 1 hora'
                        chatMessage="¡Hola, soy Horacio! ¿Cómo puedo ayudarte?"
                        placeholder='Escribe un mensaje'
                        avatar={foto}
                        allowEsc='true'
                    /> */}
      <Footer />
    </div>
  </>
);

export default MainPage;
