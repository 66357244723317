/* esta funcion tiene la responsabilidad de agregar, quitar , limpiar items del Carrito */

import { useGlobalStore } from '../../../Hooks/useGlobalStore';
import { useInfoSync } from '../../../Hooks/useInfoSync';
import { useClient } from '../../catalogo/hooks/useClient';

export const useShopingCart = () => {
  const {
    setQueryData: setQueryDataClient,
    data: dataClient,
    initClient,
  } = useClient();

  const { setQueryData: setQueryDataItems, data: dataItems } =
    useGlobalStore('Items');

  const { setQueryData: setQueryDataTotalItems, data: dataTotalItems } =
    useGlobalStore('TotalItems');

  const { data: dataInfoSync } = useInfoSync();

  // -----------------------

  /*   const initClient = () => {
    return {
      id: 0,
      denominacion: '',
    };
  }; */

  const initItems = () => {
    return [];
  };

  const currencyCart = dataInfoSync?.moneda_carrito || 0;

  const clearShopingCart = () => {
    const items = initItems();
    const total = 0;

    setQueryDataClient(null);
    setQueryDataItems(items);
    setQueryDataTotalItems(total);
  };

  const clearItems = () => setQueryDataItems(initItems());

  const setClient = (client) => {
    setQueryDataClient(client);
  };

  const getConvertedPrice = (moneda, precios) => {
    if (!dataInfoSync) return 0;
    const { moneda_carrito = 0, cotizacion = 0 } = dataInfoSync || {};
    const dollarPrice = parseFloat(cotizacion);
    const {
      descuento,
      monto_neto,
      monto_neto_con_descuento,
      monto_con_iva,
      monto_con_iva_con_descuento,
      precio_iva,
    } = precios;

    const newPrice = (price) => {
      price = parseFloat(price);
      price =
        parseInt(moneda_carrito) !== parseInt(moneda)
          ? moneda_carrito === 1
            ? price / dollarPrice
            : price * dollarPrice
          : price;
      return parseFloat(price.toFixed(2));
    };

    return {
      ...precios,
      monto_neto: newPrice(monto_neto),
      monto_neto_con_descuento: newPrice(monto_neto_con_descuento),
      descuento: newPrice(descuento),
      monto_con_iva: newPrice(monto_con_iva),
      monto_con_iva_con_descuento: newPrice(monto_con_iva_con_descuento),
      precio_iva: newPrice(precio_iva),
    };
  };

  const setItem = (articulo) => {
    const { id, denominacion, cantidad, precios, moneda } = articulo;

    const convertedPrice = getConvertedPrice(moneda, precios);

    const item = {
      id,
      denominacion,
      cantidad: cantidad,
      precios: convertedPrice,
      moneda: moneda,
    };

    console.log(item);

    let carrito = dataItems ? dataItems : initItems();

    if (carrito) {
      const posItemBus = carrito.findIndex((i) => i.id === item.id);
      if (posItemBus > -1) {
        carrito[posItemBus].cantidad = item.cantidad;
      } else {
        carrito.push(item);
      }

      setQueryDataItems(carrito);
      const initialValue = 0;
      const totalItems = carrito.reduce(
        (accum, item) => accum + item.cantidad,
        initialValue
      );
      setQueryDataTotalItems(totalItems);
    }
  };

  const getItems = () => {
    return dataItems ? dataItems : initItems();
  };

  const getTotalItems = () => {
    const items = dataItems ? dataItems : initItems();
    return items.reduce((accum, item) => accum + item.cantidad, 0);
  };

  const getTotal = () => {
    let total = 0;

    const carrito = getItems();

    carrito.map((item) => {
      return (total += item.cantidad * item?.precios?.precio);
    });
    return total;
  };

  console.log(getItems());

  const getTotalPrices = () => {
    return getItems().reduce(
      (response, { cantidad, precios }) => {
        return {
          monto_neto: response?.monto_neto + cantidad * precios?.monto_neto,
          monto_neto_con_descuento:
            response?.monto_neto_con_descuento +
            cantidad * precios?.monto_neto_con_descuento,
          descuento: response?.descuento + cantidad * precios?.descuento,
          precio_iva: response?.precio_iva + cantidad * precios?.precio_iva,
          monto_con_iva:
            response?.monto_con_iva + cantidad * precios?.monto_con_iva,
          monto_con_iva_con_descuento:
            response?.monto_con_iva_con_descuento +
            cantidad * precios?.monto_con_iva_con_descuento,
        };
      },
      {
        monto_neto: 0,
        monto_neto_con_descuento: 0,
        descuento: 0,
        monto_con_iva: 0,
        monto_con_iva_con_descuento: 0,
        precio_iva: 0,
      }
    );
  };

  const currency = getItems()[0]?.moneda || 0;

  const productListUpdated = (id, action, newValue = null) => {
    return getItems().map((p) => {
      if (p.id === id) {
        return {
          ...p,
          cantidad:
            (newValue && parseInt(newValue)) ??
            (action === 'decrease' ? p.cantidad - 1 : p.cantidad + 1),
        };
      }
      return p;
    });
  };

  const handleDiscountProduct =
    ({ id, cantidad }) =>
    () => {
      if (cantidad === 1) return;
      setQueryDataItems(productListUpdated(id, 'decrease'));
    };

  const handleIncreaseProduct =
    ({ id }) =>
    () =>
      setQueryDataItems(productListUpdated(id, 'increase'));

  const handleChangeProduct =
    ({ id }) =>
    ({ target }) =>
      setQueryDataItems(productListUpdated(id, '', target.value));

  const handleDeleteProduct =
    ({ id }) =>
    () => {
      const newList = getItems().filter((p) => p.id !== id);
      return setQueryDataItems(newList);
    };

  return {
    setItem,
    setClient,
    getItems,
    getTotalItems,
    getTotal,
    getTotalPrices,
    clearShopingCart,
    clearItems,
    dataClient,
    currency,
    handleDiscountProduct,
    handleIncreaseProduct,
    handleChangeProduct,
    handleDeleteProduct,
    currencyCart,
  };
};
