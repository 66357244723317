import React, { useState, useEffect } from 'react';
import Modal from '../../../Modal/Modal.jsx';
import { useGetPurchaseOk } from '../hooks/useGetPurchaseOk.js';
import { PrintOrder } from '../../../ShoppingCart/components/printPurchase/PrintOrder.jsx';
import './Confirm.css';
import '../../../css/Tablas.css';
import inspeccionar from './assets/inspeccionar.svg';
import useModal from '../../../../Hooks/useModal.js';
import {
  formatDate,
  calculateTotal,
  formatSucursal,
  formatNumero,
  formatPrecio,
} from '../../../ShoppingCart/hooks/useFormat.js';
import { format } from '../../../../helpers/formatter';

const safeValue = (value) => (isNaN(value) ? 0 : value);

export const Confirm = () => {
  const { pedidos, loading } = useGetPurchaseOk();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState(null);

  const openModal = (pedido) => {
    setSelectedPedido(pedido);
    setModalOpen(true);
  };

  console.log(selectedPedido);

  const closeModal = () => {
    setSelectedPedido(null);
    setModalOpen(false);
  };

  const { active, toggle } = useModal();
  const cerrarModal = () => {
    const activarDesactivar = !active;
    toggle(activarDesactivar);
    closeModal();
  };

  useEffect(() => {
    return () => {
      // Cleanup function to ensure modal is closed and states are reset
      setSelectedPedido(null);
      setModalOpen(false);
    };
  }, []);

  const getCurrencySymbol = (moneda) => (moneda === 0 ? 'ARS$ ' : 'US$ ');

  return (
    <>
      <div className="pageConfirmContainer">
        <h1 className="historialTitu">Historial de Pedidos</h1>
        {loading && <p>Cargando...</p>}

        {!loading &&
          pedidos &&
          pedidos.map((pedido) => {
            const finalPrice = pedido.total;
            const formattedSucursal = formatSucursal(pedido.sucursal_mg || '0');
            const formattedNumero = formatNumero(pedido.numero_mg || '0');
            const moneda = pedido.Comprobantes_items[0]?.moneda || 0; // moneda value

            return (
              <article className="contenedorCardConfirm" key={pedido.id}>
                <section className="cardConfirm">
                  <p className="fechaPed">{formatDate(pedido.fecha)}</p>

                  <div className="contenedorSucNum">
                    <span>{formattedSucursal} - </span>
                    <span>{formattedNumero}</span>
                  </div>
                  <div className="contenedorDenom">
                    <button onClick={() => openModal(pedido)}>
                      <span className="denominacion">
                        {pedido.Cliente
                          ? pedido.Cliente.codigo_mg
                          : 'Cliente no encontrado'}
                        {' - '}
                        {pedido.Cliente
                          ? pedido.Cliente.denominacion
                          : 'Denominacion no encontrada'}
                      </span>
                    </button>
                  </div>

                  <h2 className="totalItems">
                    {format.currency(moneda, safeValue(finalPrice))}
                  </h2>
                </section>
              </article>
            );
          })}
      </div>

      {modalOpen && selectedPedido && (
        <Modal titulo={'Detalle'} cerrarModal={cerrarModal}>
          <PrintOrder pedido={selectedPedido} cerrarModal={cerrarModal} />
        </Modal>
      )}
    </>
  );
};
