import { useContext, useEffect, useState } from 'react';
import { back } from '../../helpers/dataServer';
import { ItemSelect } from './ItemSelect';
import { UserContext } from '../auth/context/UserContext';

const ItemPefilUsuario = ({ usuario }) => {
  const [perfiles, setPerfiles] = useState([]);
  const [usuarioPerfil, setUsuarioPerfil] = useState();
  const [abierto, setAbierto] = useState(false);

  const { dataUser } = useContext(UserContext);

  const { token } = dataUser;

  useEffect(() => {
    setUsuarioPerfil(usuario.id_perfil);
  }, [usuario.id_perfil]);

  useEffect(() => {
    fetch(`${back}/perfiles/listperfiles`, {
      headers: {
        authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => setPerfiles(data.data))
      .catch((error) => {
        console.error('Error al obtener la lista de perfiles:', error);
      });
  }, [token]);

  const toggleAcordeon = () => {
    setAbierto(!abierto);
  };

  return (
    <section className="cardManagementProfile" onClick={toggleAcordeon}>
      <img
        className="imgGestPerfil"
        src={usuario.imagen}
        alt="Imagen de perfil"
      />
      <span className="nombreGestPerfil">
        {usuario.nombre} {usuario.apellido}
      </span>
      <span className="perfilesGest">
        <ItemSelect usuario={usuario} abierto={abierto} toggleAcordeon={toggleAcordeon} />
      </span>
    </section>
  );
};

export default ItemPefilUsuario;



// import { useContext, useEffect, useState } from 'react';
// import { back } from '../../helpers/dataServer';
// import { ItemSelect } from './ItemSelect';
// import { UserContext } from '../auth/context/UserContext';

// const ItemPefilUsuario = ({ usuario }) => {
//   const [perfiles, setPerfiles] = useState([]);
//   const [usuarioPerfil, setUsuarioPerfil] = useState();

//   const { dataUser } = useContext(UserContext);

//   const { token } = dataUser;

//   useEffect(() => {
//     setUsuarioPerfil(usuario.id_perfil);
//   }, []);

//   useEffect(() => {
//     fetch(`${back}/perfiles/listperfiles`, {
//       headers: {
//         authorization: token,
//         'Content-Type': 'application/json',
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => setPerfiles(data.data))
//       .catch((error) => {
//         console.error('Error al obtener la lista de perfiles:', error);
//       });
//   }, []);

//   return (
//     <section className="cardManagementProfile">
//       <img
//         className="imgGestPerfil"
//         src={usuario.imagen}
//         alt="Imagen de perfil"
//       />
//       <span className="nombreGestPerfil">
//         {usuario.nombre} {usuario.apellido}
//       </span>
//       <span className="perfilesGest">
//         <ItemSelect usuario={usuario} />
//       </span>
//     </section>
//   );
// };

// export default ItemPefilUsuario;
