/* USO DE COOKIES */

import { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useDataUser } from '../../../Hooks/useDataUser';

const fechaCaducidad = new Date();
fechaCaducidad.setDate(fechaCaducidad.getDate() + 30);

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { setUser } = useDataUser();
  const cookies = new Cookies();

  // Obtener datos de usuario de las cookies al inicio
  const initialUserData = cookies.get('userData') || {};
  const [dataUser, setDataUser] = useState(initialUserData);

  const [carrito, setCarrito] = useState([]); // Estado para el carrito

  const actuUser = (userData, token) => {
    // Combinar los datos del usuario con el token en un solo objeto
    const userWithToken = { ...userData, token };
    setUser(userWithToken);
    cookies.set('userData', userWithToken, {
      path: '/',
      expires: fechaCaducidad,
    });
    setTimeout(() => {
      setDataUser(userWithToken);
    }, 0);
  };

  /*CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO CARRO*/
  // Cargar datos de usuario y carrito desde localStorage al inicio
  useEffect(() => {
    const storedCarrito = localStorage.getItem('carritoData');
    if (storedCarrito) {
      setCarrito(JSON.parse(storedCarrito));
    }
  }, []);

  //Guardar datos de usuario y carrito en localStorage cuando cambian
  useEffect(() => {
    localStorage.setItem('carritoData', JSON.stringify(carrito));
  }, []);

  const agregarAlCarrito = (item) => {
    // Clonar el carrito actual para evitar mutaciones directas del estado
    const nuevoCarrito = [...carrito];

    // Agregar el nuevo artículo al carrito
    nuevoCarrito.push(item);

    // Actualizar el estado del carrito en el contexto
    setCarrito(nuevoCarrito);
  };

  const eliminarItemDelCarrito = (index) => {
    // Clonar el carrito actual
    const nuevoCarrito = [...carrito];

    // Eliminar el artículo en el índice especificado
    nuevoCarrito.splice(index, 1);

    // Actualizar el estado del carrito en el contexto
    setCarrito(nuevoCarrito);
  };

  const actualizarCantidad = (index, cantidad) => {
    // Clonar el carrito actual
    const nuevoCarrito = [...carrito];

    // Actualizar la cantidad del artículo en el índice especificado
    nuevoCarrito[index].cantidad = cantidad;

    // Actualizar el precio total del artículo
    nuevoCarrito[index].precioTotal = nuevoCarrito[index].precio * cantidad;

    // Actualizar el estado del carrito en el contexto
    setCarrito(nuevoCarrito);
  };
  //CARRO
  // Crea la función clearUserData para borrar los datos del usuario
  const clearUserData = () => {
    // Borra los datos del usuario del estado
    setDataUser({});
    setUser({});
    // Borra los datos del usuario de las cookies
    cookies.remove('userData', { path: '/' });
  };

  return (
    <UserContext.Provider
      value={{
        dataUser,
        actuUser,
        carrito,
        agregarAlCarrito,
        eliminarItemDelCarrito,
        actualizarCantidad,
        clearUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

/** SCRIPT ANTERIOR USANDO LOCALSTORAGE */

/* import { createContext, useContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [dataUser, setDataUser] = useState(JSON.parse(localStorage.getItem('userData'))?JSON.parse(localStorage.getItem('userData')):{});
  const [carrito, setCarrito] = useState([]); // Estado para el carrito

  // Cargar datos de usuario y carrito desde localStorage al inicio
  useEffect(() => {
    console.log('montamos la aplicacion');
    const storedUser = localStorage.getItem('userData');
    const storedCarrito = localStorage.getItem('carritoData');

    if (storedUser) {
      setDataUser(JSON.parse(storedUser));
      console.log('storedUser',storedUser);
    }

    // if (storedCarrito) {
    //   setCarrito(JSON.parse(storedCarrito));
    // }

  }, []);

  // Guardar datos de usuario y carrito en localStorage cuando cambian
  useEffect(() => {
    console.log('se modifican los estados de  datauser y carrito');
    localStorage.setItem('userData', JSON.stringify(dataUser));
    localStorage.setItem('carritoData', JSON.stringify(carrito));
    console.log(dataUser)
  }, [dataUser]);

  const actuUser = (user) => {
    console.log(user);
    setDataUser(user);
  };

  const agregarAlCarrito = (item) => {
    // Clonar el carrito actual para evitar mutaciones directas del estado
    const nuevoCarrito = [...carrito];

    // Agregar el nuevo artículo al carrito
    nuevoCarrito.push(item);

    // Actualizar el estado del carrito en el contexto
    setCarrito(nuevoCarrito);
  };

  const eliminarItemDelCarrito = (index) => {
    // Clonar el carrito actual
    const nuevoCarrito = [...carrito];

    // Eliminar el artículo en el índice especificado
    nuevoCarrito.splice(index, 1);

    // Actualizar el estado del carrito en el contexto
    setCarrito(nuevoCarrito);
  };

  const actualizarCantidad = (index, cantidad) => {
    // Clonar el carrito actual
    const nuevoCarrito = [...carrito];

    // Actualizar la cantidad del artículo en el índice especificado
    nuevoCarrito[index].cantidad = cantidad;

    // Actualizar el precio total del artículo
    nuevoCarrito[index].precioTotal = nuevoCarrito[index].precio * cantidad;

    // Actualizar el estado del carrito en el contexto
    setCarrito(nuevoCarrito);
  };

    // Crea la función clearUserData para borrar los datos del usuario
    const clearUserData = () => {
      // Borra los datos del usuario del estado
      setDataUser({});

      // Borra los datos del usuario del localStorage
      localStorage.removeItem('userData');
    };

  return (
    <UserContext.Provider
      value={{ dataUser, actuUser, carrito, agregarAlCarrito, eliminarItemDelCarrito, actualizarCantidad, clearUserData }}
    >
      {children}
    </UserContext.Provider>
  );
};

*/
