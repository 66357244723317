import { useState, useEffect, useContext } from 'react';
import { API_COD_POSTAL } from '../services/ApiRoutes';
import { UserContext } from '../../auth/context/UserContext';

export const useCodPostal = (id) => {
  const [codPostal, setCodPostal] = useState('cargando');

  const { dataUser } = useContext(UserContext);
  const { token } = dataUser;

  useEffect(() => {
    if (!id) return;
    fetch(`${API_COD_POSTAL}${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error en la solicitud');
        }
      })
      .then((data) => {
        if (data.status === 200) {
          setCodPostal(data.data);
        } else {
          throw new Error(data.detail);
        }
      })
      .catch((error) => {
        setCodPostal('Error');
      });
  }, [id]);

  return codPostal;
};
