import { useNavigate } from 'react-router-dom';
import { useShopingCart } from '../../../ShoppingCart/hooks/useShopingCart';
import { usePermissions } from '../../../../Hooks/usePermissions';
import { SHOPPING_CART } from '../../../../helpers/features';

import './userShopingCart.css';

export const UserShopingCart = () => {
  const { getTotalItems } = useShopingCart();
  const navigate = useNavigate();
  const { enableFeature } = usePermissions();

  const handleClick = () => {
    enableFeature(SHOPPING_CART) && navigate('/productos');
  };

  return (
    <div className="cart" onClick={handleClick}>
      <img
        className={`imgCart`}
        src={`/assets/cart${getTotalItems() > 0 ? 'Green' : ''}.svg`}
        alt="carrito"
      />
      <span className={`text ${getTotalItems() > 0 ? 'textGreen' : ''}`}>
        {getTotalItems()}
      </span>
    </div>
  );
};
