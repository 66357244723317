import React, { useState, useContext } from 'react';
import './css/Nico.css';
import './css/Styles.css';
// import './css/modal.css';
import { Link } from 'react-router-dom';
import { UserContext } from './auth/context/UserContext';

const Perfil = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const abrirModal = () => {
    setModalVisible(true);
  };

  const cerrarModal = () => {
    setModalVisible(false);
  };

  // Accede al contexto UserContext para obtener los datos del usuario
  const { dataUser } = useContext(UserContext);
  const userData = dataUser;
  return (
    <div>
      {/* Modal de Perfil */}
      <div
        id="modal"
        className="modal"
        style={{ display: modalVisible ? 'block' : 'none' }}
      >
        {/* Contenido del Modal */}
        <article className="modal-content">
          <article className="modal-inside">
            <span id="closeModal" className="close" onClick={cerrarModal}>
              &times;
            </span>
            <br />
            <Link to="/security/editperfil" onClick={cerrarModal}>
              <img
                className="imagen_perfil"
                src={userData.imagen}
                alt="Imagen de perfil"
                height="180px"
                width="180px"
              />
            </Link>
            <h2>{userData ? `${userData.nombre} ${userData.apellido}` : ''}</h2>
            <Link to="/security/editperfil" onClick={cerrarModal}>
              <h4 className="h4_edit">
                Editar Perfil <i className="fa-regular fa-pen-to-square" />
              </h4>
            </Link>
            <hr style={{ color: 'gray' }} />
            <div className="info-perfil">
              <div className="info-perfil-inside">
                <h4>Nombre</h4>
                <p style={{ color: 'gray' }} className="noDesbordaTexto">
                  {userData ? userData.nombre : ''}
                </p>
              </div>
              <hr />
              <div className="info-perfil-inside">
                <h4>Apellido</h4>
                <p style={{ color: 'gray' }} className="noDesbordaTexto">
                  {userData ? userData.apellido : ''}
                </p>
              </div>
              <hr />
              <div className="info-perfil-inside">
                <h4>Email</h4>
                <p style={{ color: 'gray' }} className="noDesbordaTexto">
                  {userData ? userData.email : ''}
                </p>
              </div>
            </div>
          </article>
        </article>
      </div>
      {/* abrir Modal Perfil */}
      <img
        className="imagen_perfil"
        src={userData.imagen}
        alt="perfil"
        style={{ height: '50px', width: '50px' }}
        id="openModal"
        onClick={abrirModal}
      />
    </div>
  );
};

export default Perfil;
