import { Route, Routes } from "react-router-dom";
import CatalogoPage from "../pages/CatalogoPage";
import Catalogcuadricula from "../Catalogcuadricula";
import { Confirm } from "../../Productos/components/history/Confirm";
import { PrintMail } from "../../ShoppingCart/components/printPurchase/PrintMail";

export const CatalogRoutes = () => (
  <Routes>
    <Route path="catalogo" element={<CatalogoPage />} />
    <Route path="catalogcuadricula" element={<Catalogcuadricula />} />
    <Route path="purchasesConfirm" element={<Confirm />} />
    <Route path="purchases/:id" element={<PrintMail />} />
  </Routes>
);
