import { CatalogList } from '../../CatalogList';

export const CatalogGrid = ({ lisArt }) => {
  return (
    <div className="catalogocuadricula">
      <div className="contenedorcuadricula">
        <CatalogList lisArt={lisArt} />
      </div>
    </div>
  );
};
