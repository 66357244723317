import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import isPasswordMatch from './funciones/ConfirmPasswordMatch';
import isPasswordValid from './funciones/PasswordValidation';
import '../css/Styles.css';
import '../css/Nico.css';
import { back, front } from '../../helpers/dataServer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Register = () => {
  const [tokenValid, setTokenValid] = useState(false);
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState('');
  const [id_propietario, setPropietario] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errores, setErrores] = useState([]);
  const imagen = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRegistro, setShowPasswordRegistro] = useState(false);
  const [showPasswordRegistroConfirm, setShowPasswordRegistroConfirm] =
    useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    // Verificar el token en el lado del cliente
    try {
      const decoded = jwt_decode(token);

      if (decoded.email) {
        const fechahora = new Date();
        const { exp } = decoded;
        console.log(fechahora.getTime().toString());

        if (Date.now() >= exp * 1000) {
          alert('token expirado');
          setTokenValid(false);
        } else {
          setTokenValid(true);
          setEmail(decoded.email);
          setPropietario(decoded.propietario);
        }
      } else {
        setTokenValid(false);
      }
    } catch (error) {
      console.error('Token no válido', error);
      setTokenValid(false);
    }
  }, []);

  const handleNombreChange = (event) => {
    const newNombre = event.target.value;
    setNombre(newNombre);
  };

  const handleApellidoChange = (event) => {
    const newApellido = event.target.value;
    setApellido(newApellido);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilityRegistro = () => {
    setShowPasswordRegistro(!showPasswordRegistro);
  };

  const togglePasswordVisibilityRegistroConfirm = () => {
    setShowPasswordRegistroConfirm(!showPasswordRegistroConfirm);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !isPasswordValid(password) ||
      !isPasswordMatch(password, confirmPassword)
    ) {
      console.log('Contraseña no válida o no coinciden.');
      return;
    }

    const userData = {
      nombre,
      apellido,
      email,
      clave: password,
    };

    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('apellido', apellido);
    formData.append('email', email);
    formData.append('clave', password);
    formData.append('imagen', imagen.current.files[0]);
    formData.append('id_propietario', id_propietario);

    fetch(`${back}/auth/create`, {
      method: 'POST',
      body: formData,
    })
      .then((result) => result.json())
      .then((response) => {
        setErrores(response.errors.errors);
        if (!response.errors.errors) {
          navigate('/auth/login');
        }
      })
      .catch((error) => {
        console.error('Error en la solicitud:', error);
      });
  };

  return (
    <div className="granContenedorLogin">
      <div className="contenedor">
        {/* <div className="logo">
          <img
            src={`${process.env.PUBLIC_URL}http://www.hydraser.com.ar/images/logo.png`}
            alt="Logo"
          />
        </div> */}
        {!tokenValid && <h2> token invalido </h2>}
        {tokenValid && (
          <div className="formulario">
            <div className="contenedorLogo">
              <img src="/logoms.png" alt="logo" className="logoLogin" />
              <span className="logoTitle">MACROSISTEMAS S.R.L.</span>
            </div>
            <h2>Registrarse</h2>
            <form onSubmit={handleSubmit}>
              <label htmlFor="nombre">Nombre:</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={nombre}
                onChange={handleNombreChange}
                required
              />
              {errores && errores.nombre && (
                <p className="warning">{errores.nombre.msg}</p>
              )}

              <label htmlFor="apellido">Apellido:</label>
              <input
                type="text"
                id="apellido"
                name="apellido"
                value={apellido}
                onChange={handleApellidoChange}
                required
              />
              {errores && errores.apellido && (
                <p className="warning">{errores.apellido.msg}</p>
              )}

              <label htmlFor="correo">Correo electrónico:</label>
              <div className="correo">
                <h3>{email}</h3>
              </div>
              <br />

              <label htmlFor="imagen" className="file-select-label">
                Imagen de Perfil:
              </label>
              <div className="file-select">
                <label htmlFor="imagen" className="file-select-label" />
                <input
                  type="file"
                  ref={imagen}
                  id="imagen"
                  name="imagen"
                  required
                  className="file-select-input"
                />
                {errores && errores.imagen && (
                  <p className="warning">{errores.imagen.msg}</p>
                )}
              </div>

              {/* <label htmlFor="suboImagen">Imagen de Perfil:</label>
          <div className='suboImagen'>
            <input 
            type="file" 
            ref={imagen} 
            id="suboImagen"
            name="suboImagen"
            required />
            {errores && errores.imagen && <p className='warning'>{errores.imagen.msg}</p>}
          </div> */}

              <br />
              <label htmlFor="clave">Contraseña:</label>
              <div className="passinput">
                <input
                  type={showPasswordRegistro ? 'text' : 'password'}
                  id="clave"
                  name="clave"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  className={!isPasswordValid(password) ? 'error' : ''}
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibilityRegistro}
                >
                  {showPasswordRegistro ? (
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye-slash"
                      aria-hidden="true"
                      className="ojo"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye"
                      aria-hidden="true"
                      className="ojo"
                    />
                  )}
                </button>
              </div>
              {errores && errores.apellido && (
                <p className="warning">{errores.apellido.msg}</p>
              )}
              {!isPasswordValid(password) && (
                <div className="error-message">
                  Clave de 8 caracteres mínimo, 1 caracter especial, 1 número, 1
                  letra mayúscula y una minúscula.
                </div>
              )}

              <label htmlFor="confirmar-clave">Confirmar contraseña:</label>
              <div className="passinput">
                <input
                  type={showPasswordRegistroConfirm ? 'text' : 'password'}
                  id="confirmar-clave"
                  name="confirmar-clave"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  className={
                    !isPasswordMatch(password, confirmPassword) ? 'error' : ''
                  }
                />

                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibilityRegistroConfirm}
                >
                  {showPasswordRegistroConfirm ? (
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye-slash"
                      aria-hidden="true"
                      className="ojo"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye"
                      aria-hidden="true"
                      className="ojo"
                    />
                  )}
                </button>
              </div>
              {!isPasswordMatch(password, confirmPassword) && (
                <div className="error-message-pass">
                  Las contraseñas no coinciden.
                </div>
              )}
              <div className="finalform">
                <input
                  type="submit"
                  value="Registrarse"
                  disabled={
                    !isPasswordValid(password) ||
                    !isPasswordMatch(password, confirmPassword)
                  }
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
