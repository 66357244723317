import addPlus from '../../assets/addplus.svg';
import imgArticulo from '../../../noimg.svg';
import './comprasAnteriores.css';

const ComprasAnteriores = ({ titulo }) => {
  <div className="contenedorComprasAnt">
    <section>
      <h3 className="tituloCompAnt">{titulo}</h3>
    </section>
    <hr className="lineaComprasAnt" />
    <div className="contenedorCardsAnt">
      <article className="cardAnt">
        <section>
          <div className="imgComprasAnt">
            <img src={imgArticulo} alt="Descripción de la imagen" />
            <div>
              <img className="addItems" src={addPlus} alt="añade items" />
            </div>
          </div>
          <hr />
          <div className="comprasAntTotArt">
            <h3 className="comprasAntTot">$10.880</h3>
            <h3 className="comprasAntArt">Articulo</h3>
          </div>
        </section>
      </article>
    </div>
  </div>;
};
export default ComprasAnteriores;
