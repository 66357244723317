import { format } from '../../../../helpers/formatter';

import { useShopingCart } from '../../hooks/useShopingCart';
import './ResumeCard.css';

const safeValue = (value) => (isNaN(value) ? 0 : value);

export const ResumeCardChild = ({
  monto_con_iva,
  monto_neto,
  precio_iva,
  porc_descuento,
  descuento,
}) => {
  const { currencyCart } = useShopingCart();

  //ARMADO DE PEDIDO
  const finalPrice = monto_con_iva * (1 - porc_descuento / 100);

  let descuentazo = monto_neto / porc_descuento;

  if (descuentazo === Infinity) {
    descuentazo = 0;
  }

  return (
    <>
      <header>
        <h2 className="tituloResCompra">Resumen del Pedido</h2>
      </header>
      <section className="contenedorTotal">
        <div className="summary">
          <div className="resumeItems">
            <span>Precio con iva</span>
            <span>
              {format.currency(currencyCart, safeValue(monto_con_iva))}
            </span>
          </div>
          <div className="resumeItems">
            <span>Precio sin Iva</span>
            <span>{format.currency(currencyCart, safeValue(monto_neto))}</span>
          </div>
          <div className="resumeItems">
            <span>Precio Iva</span>
            <span>{format.currency(currencyCart, safeValue(precio_iva))}</span>
          </div>
          {porc_descuento && (
            <div className="resumeItems">
              <span>Descuento</span>
              <span>
                {`(${format.percent(porc_descuento)}) ${format.currency(currencyCart, safeValue(descuentazo))}`}
              </span>
            </div>
          )}
          <div className="resumeItems">
            <span>Total</span>
            <span className="styleTotal">
              {format.currency(currencyCart, safeValue(finalPrice))}
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
