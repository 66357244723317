import React, { useState } from 'react';
import { usePropieties } from './hooks/usePropieties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useURLParams } from './hooks/useURLParams';
import { Arrow } from './components/UI/Arrow/Arrow';
import { Badge } from './components/UI/Badge/Badge';

import '../css/Filtros.css';
import { useFilters } from './hooks/useFilters';
import { Loading } from './components/Loading/Loading';

export const Filtros = ({ visible, toggleFiltros }) => {
  if (!visible) return null;
  return <FiltrosContent toggleFiltros={toggleFiltros} />;
};

const FiltrosContent = ({ toggleFiltros }) => {
  const { updateParms, searchParams } = useURLParams();
  const [seleccionados, setSeleccionados] = useState(() => {
    const paramFilters = searchParams.get('filters');
    return paramFilters
      ? new Set(paramFilters.split(',').map(Number))
      : new Set();
  });
  const [groupSelected, setGroupSelected] = useState(null);
  const [stock, setStock] = useState(
    Number(searchParams.get('stock')) === 1 ? 1 : 0
  );
  const { data: filterProperties, isLoading } = usePropieties();
  const { filtersCount } = useFilters();

  const handleCleanFilters = () => {
    setStock(0);
    setSeleccionados(new Set());
    updateParms(['filters', null, 'stock', null, 'pagina', 1]);
  };

  const handleCheckbox = (id) => () => {
    const newSelecteds = new Set(seleccionados);
    // Si existe lo elimina y retorna newSet sin el elemento, de lo contrario al querer eliminarlo,
    // si es que no existe, retorna false, porque no existe, por lo que lo agrega al set.
    newSelecteds.delete(id) || newSelecteds.add(id);
    setSeleccionados(newSelecteds);
    updateParms(['filters', Array.from(newSelecteds).join(',')]);
  };

  const handleStock = () => {
    setStock(stock === 0 ? 1 : 0);
    updateParms(['stock', stock === 0 ? 1 : 0]);
  };

  const toggleElement = (type) => () =>
    setGroupSelected((prevState) => (prevState === type ? null : type));

  return (
    <div className="filter-list">
      <div className="nav__filtros modal-content-global">
        <button type="button" onClick={() => toggleFiltros()}>
          <FontAwesomeIcon icon={faXmark} width={24} height={24} />
        </button>

        {isLoading ? (
          <Loading enabled />
        ) : (
          <div className="filters-container">
            {filtersCount && (
              <button
                type="button"
                className="clean-filters"
                onClick={handleCleanFilters}
              >
                Borrar filtros aplicados <Badge>{filtersCount}</Badge>
              </button>
            )}
            {filterProperties.map(({ type, properties, id }) => {
              const isOpen = groupSelected === type;
              return (
                <div
                  key={id}
                  className={`group-items ${isOpen ? 'is-open' : ''}`}
                >
                  <button
                    id="openFilter"
                    type="button"
                    onClick={toggleElement(type)}
                  >
                    <h2 htmlFor="openFilter">{type}</h2>
                    <Arrow isOpen={isOpen} />
                  </button>
                  {isOpen && (
                    <ul>
                      {properties.map((p) => (
                        <li key={p.id}>
                          <label htmlFor={p.id} className="filter-checkbox">
                            <input
                              id={p.id}
                              name={p.denomination}
                              type="checkbox"
                              checked={seleccionados.has(p.id)}
                              onChange={handleCheckbox(p.id)}
                            />
                            {p.denomination}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
            <button
              id="openFilter"
              type="button"
              onClick={toggleElement('stock')}
            >
              <h2 htmlFor="openFilter">Filtrar con Stock</h2>
            </button>
            <label htmlFor="stock" className="filter-checkbox">
              <input
                id="stock"
                name="stock"
                type="checkbox"
                checked={stock}
                onChange={handleStock}
              />
              Filtrar productos con Stock
            </label>
          </div>
        )}
      </div>
      <div className="modal-background" onClick={() => toggleFiltros()}></div>
    </div>
  );
};

export default Filtros;
