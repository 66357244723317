import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Importar SweetAlert2
import { back } from '../../helpers/dataServer';
import send from './assets/send.svg';
import { UserContext } from '../auth/context/UserContext';
export const NuevosUsuarios = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState({});

  const { dataUser } = useContext(UserContext);

  const { token } = dataUser;

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const enviarCorreo = async (e) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ email }),
    };

    const res = await fetch(`${back}/nuevousuario`, requestOptions);
    const data = await res.json();

    console.log('res');
    console.log(res);

    console.log('data');
    console.log(data);

    console.log(data.errors.status);
    console.log(data.errors);
    if (data.errors.status === 401 || !data) {
      <div className="error_msg">
        {' '}
        {error && error.msg && <p> {error.msg}</p>}
      </div>;
    } else {
      if (data.errors.status === 200) {
        Swal.fire({
          title: 'Invitación enviada con éxito',
          text: 'La invitación estará vigente 1 día a partir de este momento.',
          icon: 'success',
        });
        // alert('invitacion enviada con exito, esto tiene que ser un cartelito no un mensaje')
        // const decodedToken = jwt_decode(data.errors.token);
        // const { email } = decodedToken
        // console.log(email);
      }
      setEmail('');
      navigate('/auth/login');
    }
  };

  return (
    <div className="addNewUserContainer">
      <h1 className="titleNewUser">Invita un Nuevo Usuario</h1>
      <p className="paragraphNewUser">
        Introduce el correo electrónico del usuario que deseas invitar.
      </p>
      <p className="paragraphNewUser">
        La invitación será válida durante 24 horas.
      </p>
      <article className="cardNewUser">
        <header>
          <p className="titleNewUserCard">Usuario a invitar</p>
        </header>
        <div className="">
          <input
            type="email"
            id="search-input"
            className="search-input"
            style={{ width: 310 }}
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="">
          <button className="btnSendNewUser" onClick={enviarCorreo}>
            <img src={send} alt="" className="svgSend" />
            Enviar invitación
          </button>
        </div>
      </article>
    </div>
  );
};
