import { format } from '../../../../../helpers/formatter';
import { Articulo } from '../../../../Productos/Articulo';
import { ButtonsActionsCart } from '../../ButtonsActionsCart/ButtonsActionsCart';

export const ProductCard = ({
  articulo,
  stockUnitario,
  showActions,
  precios,
  priceToDisplay,
}) => {
  const { sinonimo, codigo_mg, moneda } = articulo;
  return (
    <div id="catalogo" className="itemcuad">
      <div className="buttons-actions-carts-content f-c g-8">
        <Articulo articulo={articulo} />
        <ul className="catalog-grid-content-detail">
          <li>
            <span>Código: </span>
            <span>{codigo_mg}</span>
          </li>
          <li>
            <span>Sinónimo: </span>
            <span>{sinonimo}</span>
          </li>
          <li>
            <span className="stock-producto">
              <strong>{stockUnitario || 0} </strong> unidades
            </span>
            <span className="precio-producto">
              {format.currency(moneda, priceToDisplay) || 0}
            </span>
          </li>
        </ul>
      </div>
      {showActions && (
        <div className="actions-bottom">
          <ButtonsActionsCart articulo={articulo} precios={precios} />
        </div>
      )}
    </div>
  );
};
