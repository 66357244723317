import React, { useContext } from 'react';
import { UserContext } from '../../../auth/context/UserContext';
import Swal from 'sweetalert2';
import { API_SEND_PURCHASE } from '../../services/ApiRoutes';
import {
  formatSucursal,
  formatNumero,
  calculateTotal,
  formatDate,
  formatPrecio,
} from '../../hooks/useFormat';
import sendMail from './assets/sendMail.svg';
import printer from './assets/printer.svg';
import './PrintOrder.css';
import '../../../css/Tablas.css';
import { ResumeCard } from '../resumeCard/ResumeCard';
import { PrintGridOrder } from '../printGridPurchase/PrintGridOrder';
import { useGlobalStore } from '../../../../Hooks/useGlobalStore';
import { ResumeCardChild } from '../resumeCard/ResumeCardChild';

export const PrintOrder = ({ pedido }) => {
  const total = pedido.Comprobantes_items.reduce(
    (acc, item) =>
      acc + (parseFloat(item.precio) || 0) * (parseFloat(item.cantidad) || 0),
    0
  );
  const { dataUser } = useContext(UserContext);
  const email = dataUser.email;
  const monto_con_iva = pedido.total;
  const monto_neto = pedido.total_sin_iva;
  const precio_iva = pedido.precio_iva;
  const porc_descuento = pedido.porc_descuento;
  const descuento = pedido.monto_descuento;
  console.log(pedido);
  const { token } = dataUser;

  const imprimir = () => {
    window.print();
  };

  const enviarMail = async () => {
    try {
      const response = await fetch(`${API_SEND_PURCHASE}${pedido.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ email, pedido }),
      });
      const data = await response.json();

      if (response.ok) {
        Swal.fire({
          title: 'Correo Enviado',
          text: 'El correo electrónico se ha enviado correctamente.',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al enviar el correo electrónico. Por favor, inténtalo de nuevo más tarde.',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al enviar el correo electrónico. Por favor, inténtalo de nuevo más tarde.',
        icon: 'error',
      });
    }
  };

  const getCurrencySymbol = (moneda) => (moneda === 0 ? 'ARS$ ' : 'US$ ');

  const safeFormatPrecio = (precio, cantidad) => {
    const safePrecio = isNaN(parseFloat(precio)) ? 0 : parseFloat(precio);
    const safeCantidad = isNaN(parseFloat(cantidad)) ? 0 : parseFloat(cantidad);
    return formatPrecio(safePrecio, safeCantidad);
  };
  const { data: isMobile } = useGlobalStore('isMobile');
  return (
    <div className="print-order">
      <div className="headerPrint">
        <p className="fechaPed">{formatDate(pedido.fecha)}</p>
        <p>{formatSucursal(pedido.sucursal_mg || '0')}</p>
        <p>- {formatNumero(pedido.numero_mg || '0')}</p>
      </div>
      <p className="notaPrint">Documento no válido como factura</p>
      <p className="denominacion">
        {pedido.Cliente ? pedido.Cliente.codigo_mg : 'Cliente no encontrado'}
        {' - '}
        {pedido.Cliente
          ? pedido.Cliente.denominacion
          : 'Denominacion no encontrada'}
      </p>
      <div className="bodyPrint">
        {!isMobile ? (
          <table className="tablaHistorial tabla">
            <thead className="tabla__encabezado encabezadoPrintOrder">
              <tr className="fontTablaModal">
                <th className="tabla__titulo denomTablaModal">Descripción</th>
                <th className="tabla__titulo tabla__num tabla__media widthTablaModal">
                  Precio
                </th>
                <th className="tabla__titulo tabla__num tabla__media widthTablaModal">
                  Cantidad
                </th>
                <th className="tabla__titulo tabla__num tabla__media widthTablaModal">
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {pedido.Comprobantes_items.map((item) => (
                <tr key={item.id} className="renglon-posicion">
                  <td className="tabla__contenido denomTablaModal fontTablaModal">
                    {item.Articulos}
                  </td>
                  <td className="tabla__contenido tabla__num fontTablaModal widthTablaModal">
                    {getCurrencySymbol(pedido.moneda)}
                    {item.precio}
                  </td>
                  <td className="tabla__contenido tabla__num fontTablaModal widthTablaModal">
                    {item.cantidad}
                  </td>
                  <td className="tabla__contenido tabla__num tabla__media widthTablaModal">
                    {getCurrencySymbol(pedido.moneda)}
                    {safeFormatPrecio(item.precio, item.cantidad)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <PrintGridOrder
            items={pedido.Comprobantes_items}
            getCurrencySymbol={getCurrencySymbol}
            formatPrecio={safeFormatPrecio}
            moneda={pedido.moneda}
          />
        )}

        <article className="contenedorCardTotal stickyContenedor">
          <ResumeCardChild
            monto_con_iva={monto_con_iva}
            monto_neto={monto_neto}
            precio_iva={precio_iva}
            porc_descuento={porc_descuento}
            descuento={descuento}
          />
        </article>
      </div>
      <p className="printLeyenda">Gracias por su compra.</p>
    </div>
  );
};
