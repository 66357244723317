// #.00111487.#   MsPortal   front  CRUD USUARIOS PERFILES
import React, { useContext, useEffect, useState } from 'react';
import { back } from '../../helpers/dataServer';
import ItemPefilUsuario from './ItemPefilUsuario';
import { UserContext } from '../auth/context/UserContext';
import '../css/GestionPerfiles.css';

export const GestionPerfiles = () => {
  const [usuarios, setUsuarios] = useState([]);

  const { dataUser } = useContext(UserContext);

  const { token } = dataUser;

  useEffect(() => {
    fetch(`${back}/usuariosperfiles/listusuariosperfiles`, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsuarios(data.data);
      })
      .catch((error) => {
        console.error('Error al obtener la lista de usuarios:', error);
      });
  }, []);

  return (
    <div className="GrandContainerGestionP">
      <h1>Gestión de Perfiles</h1>
      <div className="contenedortabla">
        {usuarios && usuarios.length > 0 && (
          <article className="tablaUsuarioPerfil">
            {usuarios.map((usuario) => (
              <ItemPefilUsuario usuario={usuario} />
            ))}
          </article>
        )}
      </div>
    </div>
  );
};
