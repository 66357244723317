import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imgArticulo from '../../../noimg.svg';
import removeItems from '../../assets/removeItems.svg';
import plus from '../../assets/addItems.svg';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatPrecio } from '../../hooks/useFormat';
import { useShopingCart } from '../../hooks/useShopingCart';
import { format } from '../../../../helpers/formatter';

export const RowsItems = ({ item }) => {
  const {
    handleDiscountProduct,
    handleIncreaseProduct,
    handleChangeProduct,
    handleDeleteProduct,
    currencyCart,
  } = useShopingCart();

  return (
    <article className="contenedorCardCarrito" key={item.codigo_mg}>
      <section className="cardCarrito">
        <h2 className="tituloCard contenedorTituloBtn">{item.denominacion}</h2>
        <button
          type="button"
          className="btnEliminarCard"
          onClick={handleDeleteProduct(item)}
        >
          <FontAwesomeIcon icon={faTrash} />
          Eliminar
        </button>
        <div className="contSelectorTot">
          <section className="selectorCant">
            <button
              type="button"
              className="selectorItems"
              onClick={handleDiscountProduct(item)}
            >
              <img src={removeItems} alt="Quita Items" />
            </button>
            <input
              className="selectorItems"
              onChange={handleChangeProduct(item)}
              value={item.cantidad}
            />
            <button
              type="button"
              className="selectorItems"
              onClick={handleIncreaseProduct(item)}
            >
              <img src={plus} alt="Agrega Items" />
            </button>
          </section>
          <p className="selectorStock">Stock: {item.totalStock}</p>
        </div>
        <div className="selectorTotal">
          <h2 className="totalItems">
            {format.currency(
              currencyCart,
              formatPrecio(item?.precios?.monto_con_iva, item.cantidad || 0)
            )}
          </h2>
        </div>
      </section>
    </article>
  );
};
