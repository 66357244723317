import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const PrivateRoute = ({ children }) => {
  const { dataUser } = useContext(UserContext);

  // return (dataUser.email)

  return dataUser.token ? children : <Navigate to="/auth/login" />;
};

export default PrivateRoute;
