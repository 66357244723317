import React, { useEffect, useState } from 'react';
import '../css/Acordeon.css';

const Acordeon = ({ titulo, datos }) => {
  const [abierto, setAbierto] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (datos) {
      if (titulo === 'Stock') {
        let total = 0;
        datos.map((i) => {
          total += parseInt(i.cantidad);
        });
        setTotal(total);
      } else {
        setTotal(datos[0]?.precio || 0);
      }
    }
  }, []);

  const toggleAcordeon = () => {
    setAbierto(!abierto);
  };

  return (
    <div>
      <div className={`acordeon-item ${abierto ? 'abierto' : ''}`}>
        <div className="acordeon-titulo" onClick={toggleAcordeon}>
          <div>
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 14 14"
            >
              <g
                fill="none"
                stroke="#999999"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m6.46.5l6.14 6.15a.48.48 0 0 1 0 .7L6.46 13.5" />
                <path
                  d="M1.25.5L7.4 6.65a.5.5 0 0 1 0 .7L1.25 13.5"
                  rotation={abierto ? 90 : 0}
                />
              </g>
            </svg>
          </div>
          <div>
            {titulo}: {total}
          </div>
        </div>
        <hr />

        {abierto && (
          <div className="acordeon">
            <div className="acordeon-contenido">
              {datos && datos.length > 0 && (
                <table key={datos}>
                  <tbody>
                    {datos.map((i, index) => {
                      return (
                        <tr key={index}>
                          <td className="desc">
                            {i.descripcion ? i.descripcion : i.tipo}
                          </td>
                          <td className="stock-y-prec">
                            {i.cantidad ? i.cantidad : i.precio}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Acordeon;
