import './PrintOrder.css';
import '../../../css/Tablas.css';
import { GetPurchaseId } from './services/GetPurchaseId';
import { useParams } from 'react-router-dom';
import {
  formatSucursal,
  formatNumero,
  calculateTotal,
  formatDate,
  formatPrecio,
} from '../../hooks/useFormat';
import printer from './assets/printer.svg';
export const PrintMail = () => {
  // Obtener el id del path.
  let { id } = useParams();

  const { pedido, loading, error } = GetPurchaseId(id);

  const imprimir = () => {
    window.print();
  };

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  // Calcular el total del pedido sumando los subtotales de cada artículo.
  const totalPedido = pedido.Comprobantes_items.reduce(
    (total, item) =>
      total + parseFloat(item.precio) * parseFloat(item.cantidad),
    0
  );

  return (
    <>
      <div className="pageMailContainer">
        <h1 className="mailTitu">Detalle Pedido de Compra</h1>
        <div className="headerPrint">
          <p className="fechaPed">{formatDate(pedido.fecha)}</p>
          <p>Suc: {formatSucursal(pedido.sucursal_mg || '0')}</p>
          <p>Num: {formatNumero(pedido.numero_mg || '0')}</p>
        </div>
        <p className="notaPrint">Documento no válido como factura</p>
        <p className="denominacion">
          {pedido.Cliente ? pedido.Cliente.codigo_mg : 'Cliente no encontrado'}
          {' - '}
          {pedido.Cliente
            ? pedido.Cliente.denominacion
            : 'Denominacion no encontrada'}
        </p>
        <div className="bodyPrint">
          <table className="catalogosencillo tabla">
            <thead className="tabla__encabezado encabezadoPrintOrder">
              <tr>
                <th className="tabla__titulo">Descripción</th>
                <th className="tabla__titulo tabla__num tabla__media">
                  Precio
                </th>
                <th className="tabla__titulo tabla__num tabla__media">
                  Cantidad
                </th>
                <th className="tabla__titulo tabla__num tabla__media">
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {pedido.Comprobantes_items.map((item) => (
                <tr key={item.id} className="renglon-posicion">
                  <td className="tabla__contenido">{item.Articulos}</td>
                  <td className="tabla__contenido tabla__num tabla__media">
                    {item.precio}
                  </td>
                  <td className="tabla__contenido tabla__num tabla__media">
                    {item.cantidad}
                  </td>
                  <td className="tabla__contenido tabla__num tabla__media">
                    {formatPrecio(item.precio, item.cantidad)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3 className="totalPrint">
            Total: <strong>{calculateTotal(pedido)}</strong>
          </h3>
        </div>
        <p className="printLeyenda">Gracias por su compra.</p>
        <div className="footerPrint">
          <button className="buttonPrint" onClick={imprimir}>
            <img src={printer} alt="" className="imgPrintBtn" /> Imprimir
          </button>
        </div>
      </div>
    </>
  );
};
