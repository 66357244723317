import { useState } from 'react';
import { useCliente } from '../hooks/useCliente';
import { useClient } from '../hooks/useClient';
import './clientes.css';

export const Clientes = ({ cerrarModal }) => {
  const [filter, setFilter] = useState('');
  const [cliente, setCliente] = useState([]);
  const filteredClientes = useCliente(filter);
  const { setQueryData: setQueryDataClient } = useClient();

  const handleSearchInputChange = (e) => {
    setFilter(e.target.value.toUpperCase());
  };

  const handleSuggestionClick = (cliente) => {
    setFilter(cliente.denominacion);
    setCliente(cliente);
  };

  const handleAcceptClick = () => {
    setQueryDataClient(cliente);
    cerrarModal();
  };

  return (
    <div className="contenedorClieyBusc">
      <input
        type="text"
        id="buscarClientes"
        className="buscarClientes"
        placeholder="Buscar..."
        value={filter}
        onChange={handleSearchInputChange}
      />
      {filter !== '' && filteredClientes.length > 0 && (
        <ul className="sugerencias">
          {filteredClientes.map((cliente) => (
            <li
              className="renglonSugerencias"
              key={cliente.id}
              onClick={() => handleSuggestionClick(cliente)}
            >
              {cliente.denominacion}
            </li>
          ))}
        </ul>
      )}

      <button
        className="btnAceptar"
        onClick={handleAcceptClick}
        type="button"
        disabled={filter === ''}
      >
        Aceptar
      </button>
    </div>
  );
};
