import React, { useState, useEffect } from 'react';
import iniciarPed from '../../assets/iniciarPedido.svg';
import Modal from '../../../Modal/Modal';
import { Clientes } from '../clientes';
import useModal from '../../../../Hooks/useModal';
import { useShopingCart } from '../../../ShoppingCart/hooks/useShopingCart';
import closesvg from '../../../Modal/assets/close.svg';
import { useClient } from '../../hooks/useClient';
import { usePermissions } from '../../../../Hooks/usePermissions';
import { SHOPPING_CART } from '../../../../helpers/features';

import './BtnIniciarPedido.css';

export const BtnIniciarPedido = () => {
  const { clearItems } = useShopingCart();
  const { data: dataClient, clearClient } = useClient();
  const { active, toggle } = useModal();
  const { enableFeature } = usePermissions();

  const eliminarCliente = () => {
    clearClient();
    clearItems();
  };

  const cerrarModal = () => {
    if (!enableFeature(SHOPPING_CART)) return;
    const activarDesactivar = !active;
    toggle(activarDesactivar);
  };

  return (
    <>
      <div className="contenedorIniciarCompra">
        {dataClient ? (
          <div className="clienteContainer">
            <button className="btnEliminarCliente" onClick={eliminarCliente}>
              <img src={closesvg} alt="close clients" />
            </button>
            <p className="textoAzul"> {dataClient.denominacion} </p>
          </div>
        ) : (
          <button className="btnIniciarCompra" onClick={cerrarModal}>
            <img src={iniciarPed} alt="Iniciar Pedido" />
            Pedido
          </button>
        )}
        {active && (
          <Modal titulo={'Clientes'} cerrarModal={cerrarModal}>
            <Clientes cerrarModal={cerrarModal} />
          </Modal>
        )}
      </div>
    </>
  );
};
