import './ContactPage.css';

export const ContactPage = () => {
  return (
    <section className="contact-page">
      <header>
        ¡Contactanos para
        <br /> <strong>mejorar tu productividad!</strong>
      </header>
      <h2>
        ¿Estás listo para potenciar tu negocio con herramientas de gestión a la
        medida de tus necesidades?
      </h2>
      <div className="contact-form">
        <h3>Aumentá tu plan para nuevas funcionalidades</h3>
        <a
          href="http://www.macrosistemassrl.com.ar/#contact"
          target="_blank"
          rel="noreferrer"
        >
          formulario de contacto
        </a>
      </div>
      <div className="contact-block">
        <p>Teléfono: +54 (03462) 437-221 Líneas Rotativas</p>
        <a href="mailto:info@macrosistemassrl.com.ar">
          info@macrosistemassrl.com.ar
        </a>
      </div>
    </section>
  );
};
