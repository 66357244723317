import { useGlobalStore } from './useGlobalStore';

const QUERY_KEY = 'dataUser';

export const useDataUser = () => {
  const { setQueryData, data } = useGlobalStore(QUERY_KEY);

  const setUser = (user) => setQueryData(user);

  return {
    data,
    setUser,
  };
};
