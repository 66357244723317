import { useGlobalStore } from '../../../Hooks/useGlobalStore';

const QUERY_KEY = 'Client';

const initClient = {
  id: 0,
  denominacion: '',
};

export const useClient = () => {
  const { setQueryData, data } = useGlobalStore(QUERY_KEY);

  const priceTypeByClient = data?.id_tipo_precio || 0;
  const clearClient = () => setQueryData(null);

  return {
    data,
    setQueryData,
    priceTypeByClient,
    initClient,
    clearClient,
  };
};
