import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useFilters = () => {
  const [searchParams] = useSearchParams();

  const filtersCount = useMemo(() => {
    const filters = searchParams.get('filters');
    const stock = searchParams.get('stock');
    const filtersCount = filters ? filters.split(',').length : 0;
    const stockCount = stock && Number(stock) === 1 ? 1 : 0;
    const response = filtersCount + stockCount;

    return response === 0 ? null : response;
  }, [searchParams]);

  return { filtersCount };
};
