import './Switch.css';

export const Switch = ({ id, checked, onChange, children, className }) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="switch">
        <span className="label">{children}</span>
        <input
          type="checkbox"
          value={checked}
          checked={checked}
          id={id}
          onChange={onChange}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};
