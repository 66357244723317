import { useGlobalStore } from './useGlobalStore';

export const PRICE_WITH_IVA = 'priceWithIva';

export const useUserConfig = (configKey) => {
  const { data: userConfig, setQueryData } = useGlobalStore('UserConfig');

  const setPriceWithIva = () =>
    setQueryData((prev) => ({
      ...prev,
      [PRICE_WITH_IVA]: !prev?.[PRICE_WITH_IVA] || false,
    }));

  return {
    userConfig: configKey ? userConfig?.[configKey] : userConfig,
    setPriceWithIva,
  };
};
