import { useState, useEffect, useContext } from 'react';
import { API_CONDITION_FISCAL } from '../services/ApiRoutes';
import { UserContext } from '../../auth/context/UserContext';

export const useDenoCondicionFiscal = (id) => {
  const [deno, setDeno] = useState('cargando');

  const { dataUser } = useContext(UserContext);
  const { token } = dataUser;

  useEffect(() => {
    if (!id) return;
    fetch(`${API_CONDITION_FISCAL}${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error al obtener la condición fiscal');
      })
      .then((data) => {
        // Verifica si la respuesta contiene un estado y detalle
        if (data.status === 200) {
          // Verifica si el estado es 200
          if (data.status === 200) {
            setDeno(data.data); // Asigna el detalle de la respuesta
          } else {
            throw new Error(data.detail); // Lanza un error con el detalle de la respuesta
          }
        } else {
          throw new Error('Respuesta del servidor no válida');
        }
      })
      .catch((error) => {
        setDeno(
          'Ocurrió un error al obtener la denominación fiscal del cliente'
        );
      });
  }, [id]);

  return deno;
};
