import { Navigate, Route, Routes } from 'react-router-dom';
import { Login, Reestablecer, Register } from '..';
import NavbarAuth from '../NavbarAuth';

export const AuthRoutes = () => (
  <Routes>
    <Route path="login" element={<Login />} titulo="Inicio de Sesión" />
    <Route
      path="register"
      element={<Register />}
      titulo="Registro de Usuario"
    />
    <Route
      path="reestablecer"
      element={<Reestablecer />}
      titulo="Reestablecer Contraseña"
    />
    <Route path="/*" element={<Navigate to="auth/login" />} />
  </Routes>
);
