import { useClient } from '../../../catalogo/hooks/useClient';
import { useCodPostal } from '../../hooks/useCodPostal';
import { useDenoCondicionFiscal } from '../../hooks/useDenoCondicionFiscal';

export const Header = () => {
  const { data: dataClient } = useClient();

  const {
    codigoPostal,
    cuit,
    denominacion,
    domicilio,
    telefonos,
    idCondicionFiscal,
  } = dataClient || {};

  const CondFis = useDenoCondicionFiscal(idCondicionFiscal);
  const CodPostal = useCodPostal(codigoPostal);

  let codPostalSub = '';
  let ciudadSub = '';

  if (CodPostal.denominacion) {
    codPostalSub = CodPostal.denominacion.substring(0, 6);
    ciudadSub = CodPostal.denominacion.substring(6);
  }

  const renderCondFis = () => {
    if (CondFis === 'cargando') {
      return <p>Cargando condición fiscal...</p>;
    } else {
      return <p>I.V.A.: {CondFis}</p>;
    }
  };

  return (
    <article className="cardMembrete">
      <header>
        <h1 className="tituloMembrete">{denominacion}</h1>
      </header>
      <div className="bodyMembrete">
        <div className="columnasInfoMembrete">
          {renderCondFis()}
          <p>C.U.I.T.: {cuit} </p>
        </div>
        <div className="columnasInfoMembrete">
          <p>Dirección: {domicilio} </p>
          <p>Ciudad: {ciudadSub} </p>
        </div>
        <div className="columnasInfoMembrete">
          <p>C.P.: {codPostalSub}</p>
          <p>Tel.:{telefonos}</p>
        </div>
      </div>
    </article>
  );
};
