import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './Modal.css';

const Modal = ({ titulo, cerrarModal, children }) => {
  const modalRef = useRef(null);

  const handleCloseModalOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      cerrarModal();
    }
  };

  return (
    <div className="modalOverlay" onClick={handleCloseModalOutside}>
      <div className="modal-content-global" ref={modalRef}>
        <button className="closeBtn" type="button" onClick={cerrarModal}>
          <FontAwesomeIcon icon={faXmark} width={24} height={24} alt="close" />
        </button>
        <div className="contenedorClieyBusc">
          <h3 className="clientesTitu">{titulo}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
