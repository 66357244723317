const formatCurrency =
  ({ locale, currency }) =>
  (amount = 0) => {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });

    return formatter.format(amount);
  };

const formatPercent = (number) => {
  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'percent',
    minimumFractionDigits: 2,
  });

  return formatter.format(number / 100);
};

const getMappedCurrency = (currentCurrency) =>
  ({
    0: { locale: 'es-AR', currency: 'ARS' },
    1: { locale: 'es-AR', currency: 'USD' },
  })[currentCurrency];

// export const formattedPrices = (allPrices, currentCurrency = 0) =>
//   allPrices?.map((prices) => ({
//     ...prices,
//     precio: format.currency(
//       currentCurrency,
//       prices.precio.replace('$USD ', '')
//     ),
//   }));

export const formattedPrices = (allPrices, currentCurrency = 0) =>
  allPrices?.map((prices) => ({
    ...prices,
    precio: format.currency(
      currentCurrency,
      prices.monto_neto_con_descuento.toString()
    ),
  }));

const currencyToFloat = (currencyString) => {
  const cleanedString = currencyString.replace(/[^\d.,-]/g, '');
  const normalizedString = cleanedString.replace('.', '').replace(',', '.');
  return parseFloat(normalizedString);
};

export const format = {
  ARS: formatCurrency(getMappedCurrency(0)),
  USD: formatCurrency(getMappedCurrency(1)),
  currency: (currentCurrency, amount) =>
    formatCurrency(getMappedCurrency(currentCurrency))(amount),
  percent: formatPercent,
  currencyToFloat,
};
