import React, { useEffect, useState } from 'react';
/* import '../css/NavFiltros.css'; */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import flechas from '../../public/arrow-24.png';

export const NavFiltros = (propiedades) => {
  const [filtros, setFiltros] = useState([]);

  useEffect(() => {
    setFiltros(propiedades.propiedades);
  }, [propiedades]);

  return (
    <>
      <input type="checkbox" id="toggle" />
      <label htmlFor="toggle" className="hamburger">
        {/* <Filtroicono /> */}
      </label>
      <nav className="nav__filtros">
        <ul className="list">
          <li className="list__item list__item--click">
            <label htmlFor="toggle" className="hamburger">
              <FontAwesomeIcon icon={faXmark} size="sm" />
            </label>
          </li>

          {filtros &&
            filtros.map((propiedad) => (
              <li key={propiedad.tipo} className="list__item list__item--click">
                <div className="list__button list__button--click">
                  <a href="#" className="nav__link">
                    {propiedad.tipo}
                  </a>
                  <img
                    className="flechas"
                    src={flechas} /* className="list__arrow" */
                  />
                </div>

                <ul className="list__show">
                  {propiedad.denominacion.map((deno) => (
                    <li key={deno} className="list__inside">
                      <input type="checkbox" />
                      <a href="#" className="nav__link nav__link--inside">
                        {deno}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
        </ul>
      </nav>
      {/* --FIN FILTROS--*/}
    </>
  );
};
