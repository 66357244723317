import './Arrow.css';

export const Arrow = ({ isOpen }) => (
  <span className={`icon-arrow ${isOpen ? 'icon-arrow__open' : ''} `}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 14 14"
    >
      <g
        fill="none"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m6.46.5l6.14 6.15a.48.48 0 0 1 0 .7L6.46 13.5" />
        <path d="M1.25.5L7.4 6.65a.5.5 0 0 1 0 .7L1.25 13.5" />
      </g>
    </svg>
  </span>
);
