import { useNavigate } from 'react-router-dom';
import { useInfoSync } from './useInfoSync';
import { SHOPPING_CART } from '../helpers/features';

export const usePermissions = () => {
  const { data: dataInfoSync } = useInfoSync();
  const navigate = useNavigate();

  const enableFeature = (feature) => {
    const enabled =
      {
        [SHOPPING_CART]: dataInfoSync?.usa_carrito === '1',
      }[feature] || false;

    !enabled && navigate('/contact');
    return enabled;
  };

  return { enableFeature };
};
