import './App.css';
import './components/css/Styles.css';
import { HashRouter } from 'react-router-dom';
import { UserProvider } from './components/auth/context/UserContext';
import { AppRouter } from './components/Router/AppRouter';

import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createIDBPersister } from './Utils/Indexdb';
import { InfoSync } from './components/InfoSync';
import { registerEventViewport } from './helpers/device';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const persistOptions = {
  persister: createIDBPersister(),
  maxAge: Infinity,
};

registerEventViewport(queryClient);

const App = () => (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={persistOptions}
  >
    <UserProvider>
      <HashRouter>
        <div className="App">
          <InfoSync />
          <AppRouter />
        </div>
      </HashRouter>
    </UserProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </PersistQueryClientProvider>
);

export default App;
