import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordValidation from './funciones/PasswordValidation';
import { UserContext } from './context/UserContext';
import { back, front } from '../../helpers/dataServer';
import { setStoreIsMobile } from '../../helpers/device';
import { queryClient } from '../../App';
import { useDataUser } from '../../Hooks/useDataUser';

export const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [inputClass, setInputClass] = useState('');
  const [errores, setErrores] = useState('');
  const [email, setEmail] = useState('');
  const { actuUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordRequested, setResetPasswordRequested] = useState(false);

  const [error, setError] = useState('');
  const { setUser } = useDataUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    setStoreIsMobile(window.innerWidth, queryClient);
    const userData = {
      email: e.target[0].value,
      clave: password,
    };

    fetch(`${back}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.errors.status >= 400) {
          setErrores({
            msg: 'Email o contraseña inválida',
          });
        }

        if (response.errors.status === 200) {
          const decodedToken = jwt_decode(response.errors.token);
          const { id, nombre, email, apellido, imagen, roleId, perfiles } =
            decodedToken;

          actuUser(
            {
              id,
              nombre,
              apellido,
              email,
              imagen,
              roleId,
              perfiles,
            },
            response.errors.token
          );

          navigate('/catalog/catalogo');
        }
      });
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    const isPasswordValid = PasswordValidation(newPassword);
    const isUsernameValid = newPassword.length > 3;

    const className = isPasswordValid && isUsernameValid ? '' : 'error';

    setInputClass(className);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPasswordClick = () => {
    setResetPasswordRequested(true);
  };

  const enviarCorreo = async (e) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    };

    const res = await fetch(
      `${back}/nuevousuario/sendReestablecer`,
      requestOptions
    );
    const data = await res.json();

    if (data.errors.status === 401 || !data) {
      <div className="error_msg">
        {' '}
        {error && error.msg && <p> {error.msg}</p>}
      </div>;
    } else {
      if (data.errors.status === 200) {
        alert('Mail enviado correctamente');
        // const decodedToken = jwt_decode(data.errors.token);
        // const { email } = decodedToken
        // console.log(email);
      }
      setEmail('');
      navigate('/auth/login');
    }
  };

  return (
    <div className="granContenedorLogin">
      <div className="contenedor">
        <div className="formulario">
          {resetPasswordRequested ? (
            <div>
              <div className="contenedorLogo">
                <img src="/logoms.png" alt="logo" className="logoLogin" />
                <span className="logoTitle">MACROSISTEMAS S.R.L.</span>
              </div>
              <h2>Restablecer Contraseña</h2>
              <p>
                Ingresa tu correo electrónico para restablecer tu contraseña.
              </p>
              <form>
                <label htmlFor="email">Correo electrónico:</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="finalform">
                  <button
                    className="btn btn-white"
                    onClick={() => setResetPasswordRequested(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-primary float-right"
                    onClick={enviarCorreo}
                  >
                    Enviar correo
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div>
              <div className="contenedorLogo">
                <img src="/logoms.png" alt="logo" className="logoLogin" />
                <span className="logoTitle">MACROSISTEMAS S.R.L.</span>
              </div>
              <h2>Iniciar sesión</h2>
              <form onSubmit={(e) => handleLogin(e)}>
                <label htmlFor="email">Email:</label>
                <input type="text" id="email" name="email" required />
                <div className="error_msg">
                  {' '}
                  {errores && errores.msg && <p> {errores.msg}</p>}
                </div>
                <label htmlFor="contrasena">Contraseña:</label>
                <div className="passinput">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="contrasena"
                    name="contrasena"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className={inputClass}
                  />
                  {/* Add the password visibility toggle button */}
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon
                        icon="fa-solid fa-eye-slash"
                        aria-hidden="true"
                        className="ojo"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon="fa-solid fa-eye"
                        aria-hidden="true"
                        className="ojo"
                      />
                    )}
                  </button>
                </div>
                {!PasswordValidation(password) && (
                  <div className="error-message">
                    Clave de 8 caracteres mínimo, 1 caracter especial, 1 número,
                    1 letra mayúscula y una minúscula.
                  </div>
                )}
                <br />
                <div className="finalform">
                  <input
                    type="submit"
                    value="Iniciar sesión"
                    disabled={!PasswordValidation(password)}
                  />
                  <button
                    className="btnOlvidePass"
                    onClick={handleResetPasswordClick}
                  >
                    <span>¿Olvidó su Contraseña?</span>
                  </button>

                  {/* <button
                    className="btn btn-primary float-right"
                    onClick={handleResetPasswordClick}
                  >
                    ¿Olvidó su Contraseña?
                  </button> */}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
