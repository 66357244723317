import { useQuery } from '@tanstack/react-query';
import Cookies from 'universal-cookie';

import { API } from '../helpers/API';

const QUERY_KEY = 'InfoSync';

const cookies = new Cookies();

const fetchData = async () => {
  const { data } = await API.GET(`userparams/${cookies.get('userData')?.id}`);

  return data[0] || false;
};

export const useInfoSync = () =>
  useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchData,
    gcTime: 1000 * 60 * 60 * 24, // 24 hours,
    staleTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: true,
  });
