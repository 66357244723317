import React, { useRef, useEffect, useState, useContext } from 'react';
import { PermisosModal } from './PermisosModal';
import { back, front } from '../../helpers/dataServer';
import { UserContext } from '../auth/context/UserContext';

export const Permisos = ({ titulo }) => {
  const [lisPerm, setLisPerm] = useState([]);
  const [error, setError] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const { dataUser } = useContext(UserContext);

  const { token } = dataUser;

  const abrirModal = () => {
    setModalVisible(true);
  };

  const cerrarModal = () => {
    setModalVisible(false);
  };
  const newPermiso = useRef();

  useEffect(() => {
    fetch(`${back}/permisos/list`, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((res) => {
        const respuesta = res.errors.data.listado;
        setLisPerm(respuesta);
      })
      .catch((err) => {
        console.warn(err);
      });
  }, []);

  {
    /* const handleAddRow = () => {
    const newRow = { id:0 , denominacion:	'',estado:true };
    setLisPerm([...lisPerm, newRow]);
  }; */
  }
  const handleEditClick = (id) => {
    const confirmDelete = window.confirm(
      '¿Estás seguro de eliminar este registro?'
    );
    if (confirmDelete) {
      {
        /* const updatedData = data.filter((item) => item.id !== id);
      setData(updatedData); */
      }
    }
  };

  const handleDeleteClick = (id) => {
    const confirmDelete = window.confirm(
      '¿Estás seguro de eliminar este registro?'
    );
    if (confirmDelete) {
      {
        /* const updatedData = data.filter((item) => item.id !== id);
      setData(updatedData); */
      }
    }
  };

  const handleSave = (e) => {
    const permisoNuevo = newPermiso.current.value;

    fetch(`${front}/permisos/create`, {
      method: 'POST',
      headers: {
        Autorizathion: token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        denominacion: permisoNuevo
      })
    })
      .then((response) => response.json())
      .then((res) => {
        setError(res);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <main className="main">
      <h3>{titulo}</h3>
      <div className="search-container">
        <input
          type="text"
          id="search-input"
          classNameName="search-input"
          placeholder="Buscar..."
        />
        <button onClick="search()" classNameName="search-button">
          <i className="fas fa-search search-icon" />
        </button>
        <div>
          <button onClick={abrirModal} className="button__btn-agregar">
            Alta Permiso
          </button>
          <PermisosModal isOpen={modalVisible} closeModal={cerrarModal} />
        </div>
      </div>

      <div>
        {error && error.errors && <p>{error.errors.detail}</p>}
        <table className="tabla">
          <thead className="tabla__encabezado">
            <tr>
              {/* <th className='tabla__titulo tabla__media'> Código Permiso </th> */}
              <th className="tabla__titulo tabla__media"> Permiso </th>
              <th className="tabla__titulo"> Descripción </th>
              <th className="tabla__titulo"> Estado </th>
              <th className="tabla__titulo tabla__importe">
                {/* <button onClick={handleAddRow} className='tabla__encabezado-btn'>
                <i class="fa-regular fa-square-plus"></i>
              </button> */}
              </th>
            </tr>
          </thead>
          <tbody className="tabla__contenido">
            {lisPerm.map(({ id, denominacion, estado }) => (
              <tr key={`${id}`}>
                {/* <td className='tabla__contenido tabla__num' key={idpermiso}>{idpermiso}</td> */}
                <td className="tabla__contenido tabla__text">{id}</td>
                <td className="tabla__contenido tabla__text">
                  {estado !== true ? (
                    denominacion
                  ) : (
                    <input id="deno" ref={newPermiso} />
                  )}
                </td>
                <td className="tabla__contenido">
                  {' '}
                  {estado === true ? (
                    <button
                      key={`btn${id}`}
                      onClick={(e) => {
                        handleSave(e);
                      }}
                    >
                      grabar
                    </button>
                  ) : (
                    ''
                  )}{' '}
                </td>
                <td className="tabla__contenido tabla__ico">
                  <i className="fa-sharp fa-solid fa-file-pen" />
                  <i className="fas fa-trash-alt delete-icon" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};
