import React from 'react';
import { useUser } from '../../../auth/context/UserContext';
import './Carro.css';

import { Resume } from '../resume/Resume';
import { Header } from '../header/Header';
import { ItemsCart } from '../items/ItemsCart';
import { useState } from 'react';
import { useEffect } from 'react';
import { useShopingCart } from '../../hooks/useShopingCart';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';

const Carro = () => {
  const { carrito, eliminarItemDelCarrito, actualizarCantidad } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const { getTotal, getItems } = useShopingCart();
  const [itemTotal, setItemTotal] = useState(0);

  useEffect(() => {
    const total = getTotal();
    setItemTotal(total);
    setIsLoading(false);
  }, []);

  // Calcula el total de la compra sumando los subtotales de los elementos del carrito
  const totalCompra = carrito.reduce((total, item) => {
    const itemPrecio = parseFloat(item.precio) || 0; // Convierte el precio a número o usa 0 si no es válido
    const itemCantidad = parseInt(item.cantidad, 10) || 0; // Convierte la cantidad a número entero o usa 0 si no es válido
    return total + itemPrecio * itemCantidad;
  }, 0);

  const formatCuit = (cuit) => {
    let cuitFormateado = '';
    if (cuit.length !== 11) {
      cuitFormateado = 'xx-xxxxxxxx-x';
    } else {
      const primeraParte = cuit.substring(0, 2); // Obtener las partes del CUIT
      const segundaParte = cuit.substring(2, 10);
      const terceraParte = cuit.substring(10);
      cuitFormateado = `${primeraParte}-${segundaParte}-${terceraParte}`; // Concatenar las partes con guiones
    }
    return cuitFormateado; // Devuelve el CUIT formateado
  };
  const cuitSinFormato = '20134984695';
  const cuitFormateado = formatCuit(cuitSinFormato);

  if (itemTotal === 0) {
    return (
      <div className="empty-screen">
        <p>ops.. el carrito esta vacio</p>
        <div>
          <Link to="/catalog/catalogo">
            <FontAwesomeIcon icon={faCartArrowDown} size="xl" />
            Agregar productos
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="containerCarrito">
      <Header />
      <div className="contenedorCarrito">
        <ItemsCart />
        <Resume carrito={{}} />
      </div>
    </div>
  );
};

export default Carro;
