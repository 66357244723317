import React, { useEffect, useState } from 'react';
import permisosRoles from '../../database/permisosRoles.json';

export const PermisosRoles = ({ titulo }) => {
  const [lisPerRol, setlisPerRol] = useState([]);

  useEffect(() => {
    setlisPerRol(permisosRoles);
  }, []);

  const handleAddRow = () => {
    const newRow = { idRolPer: '', idRol: '', idPerm: '', estado: 'true' };
    setlisPerRol([...lisPerRol, newRow]);
  };
  return (
    <main className="main">
      <h3>{titulo}</h3>
      <div className="search-container">
        <input
          type="text"
          id="search-input"
          className="search-input"
          placeholder="Buscar..."
        />
        <button onClick="search()" className="search-button">
          <i className="fas fa-search search-icon" />
        </button>
      </div>
      <div>
        <table className="tabla">
          <thead className="tabla__encabezado">
            <tr>
              <th className="tabla__titulo tabla__media">
                {' '}
                Código Permisos Roles{' '}
              </th>
              <th className="tabla__titulo tabla__media"> Código Roles </th>
              <th className="tabla__titulo tabla__media"> Código Permisos </th>
              <th className="tabla__titulo"> Estado </th>
              <th className="tabla__titulo tabla__importe">
                <button
                  onClick={handleAddRow}
                  className="tabla__encabezado-btn"
                >
                  <i className="fa-regular fa-square-plus" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {lisPerRol.map(({ index, idRolPer, idRol, idPerm, estado }) => (
              <tr key={`${index}${idRolPer}`}>
                <td className="tabla__contenido tabla__num" key={idRolPer}>
                  {idRolPer}
                </td>
                <td className="tabla__contenido tabla__num" key={idRolPer}>
                  {idRol}
                </td>
                <td className="tabla__contenido tabla__num" key={idRolPer}>
                  {idPerm}
                </td>
                <td className="tabla__contenido tabla__media" key={idRolPer}>
                  {estado}
                </td>
                <td className="tabla__contenido tabla__ico">
                  <i className="fa-sharp fa-solid fa-file-pen" />
                  <i className="fas fa-trash-alt delete-icon" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};
