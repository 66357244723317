import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../helpers/API';
import { useURLParams } from './useURLParams';
import { useEffect } from 'react';
import { queryClient } from '../../../App';

const QUERY_KEY = 'products';

const fetchProducts = (searchParams) => async () =>
  API.GET(`articulos/list?${searchParams}`, {
    headers: {
      'X-Client': queryClient.getQueryData(['Client'])?.id || null,
    },
  }).then((res) => res.errors);

export const useCatalog = () => {
  const { searchParams } = useURLParams();
  const queryClient = useQueryClient();
  const queryCatalog = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchProducts(searchParams),
    gcTime: 1000,
    staleTime: 1000,
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient, searchParams]);

  return queryCatalog;
};
