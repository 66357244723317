import React, { useContext, useState } from 'react';
import { API_PRODUCTS_BUY } from './ApiRoutes';
import { UserContext } from '../../auth/context/UserContext';
import Swal from 'sweetalert2';
import { useShopingCart } from '../hooks/useShopingCart';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SendBuy = ({ pedido }) => {
  const { dataUser } = useContext(UserContext);
  const { token } = dataUser;
  const { clearShopingCart, getTotal } = useShopingCart();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendBuy = () => {
    setIsLoading(true);
    fetch(API_PRODUCTS_BUY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(pedido),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error al enviar la compra');
      })
      .then((data) => {
        if (data.status === 200) {
          console.log('Compra enviada exitosamente:', data);
          clearShopingCart();
          Swal.fire({
            title: 'Pedido enviado con éxito',
            text: data.message,
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: 'Error al enviar la compra',
            text: data.errors.detail,
            icon: 'error',
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error al enviar la compra:', error);
        Swal.fire({
          title: 'Error al enviar la compra',
          text: 'Ocurrió un error al enviar la compra. Por favor, inténtalo de nuevo más tarde.',
          icon: 'error',
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <button
        className="btnTotStyle"
        type="button"
        onClick={handleSendBuy}
        disabled={getTotal() === 0 || isLoading}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <span>Enviar Compra</span>
        )}
      </button>
    </>
  );
};
