import React, { useContext } from 'react';

import '../css/Nico.css';
import '../css/Styles.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../auth/context/UserContext';
import Perfil from '../Perfil';
import { useShopingCart } from '../ShoppingCart/hooks/useShopingCart';
import { UserShopingCart } from './components';
import { queryClient } from '../../App';
import Cookies from 'universal-cookie';

// import { useShopingCart } from "../ShoppingCart/hooks/useShopingCart";

const Navbar1 = () => {
  const { dataUser, clearUserData } = useContext(UserContext);
  const { clearShopingCart } = useShopingCart();
  const cookies = new Cookies();

  const handleLogout = () => {
    // Llama a la función para limpiar los datos del usuario
    clearUserData();
    clearShopingCart();
    queryClient.clear();
    cookies.remove('userData');
  };
  // const [cliente] = useState();
  // const { getClient } = useShopingCart();
  return (
    <nav className="navbar">
      <ul className="nav">
        <label htmlFor="burger__toggle" className="burger">
          <FontAwesomeIcon icon={faBars} width={24} height={24} />
        </label>
        <br />
      </ul>
      {/* 
      <p
        className={cliente !== "Esperando Cliente" ? "textoAzul" : "textoGris"}
      >
        {getClient().denominacion}
      </p> */}

      <div className="nav navbar-nav navbar-right">
        <UserShopingCart />
        {dataUser && <span>{`${dataUser.nombre}`}</span>}
        <Perfil />
        {/* Agrega el evento onClick para manejar el logout */}
        <Link to="/auth/login" onClick={handleLogout}>
          <div className="contenedor-logout">
            <i className="fa-solid fa-right-from-bracket logout-icono" />
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar1;
