import React, { useState, useEffect } from 'react';
import '../css/ModalPerfiles.css';
import '../css/PerfilesUsuarios.css';
import Modal from '../Modal/Modal';

const ModalListaCheck = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const cerrarModal = () => {
    setModalVisible(false);
  };

  const abrirModal = () => {
    setModalVisible(true);
  };

  return (
    <>
      <button onClick={abrirModal}>Editar</button>
      {modalVisible && (
        <ModalListaCheckContent {...props} cerrarModal={cerrarModal} />
      )}
    </>
  );
};

const ModalListaCheckContent = ({
  data,
  itemSelecionados,
  setItemsSelecionados,
  setIsEdited,
  cerrarModal,
  title,
}) => {
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    const datalocal = data.map((i) => {
      return {
        id: i.id ? i.id : '',
        denominacion: i.descripcion
          ? i.descripcion
          : i.denominacion
            ? i.denominacion
            : '',
      };
    });
    setDatos(datalocal);
  }, [data]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const isChecked = (i) => {
    const encontrado = itemSelecionados.filter((j) => j.id === i.id);
    return encontrado.length > 0;
  };

  const handlePerfilChange = (i) => {
    setIsEdited(true);
    if (isChecked(i)) {
      setItemsSelecionados(itemSelecionados.filter((j) => j.id != i.id));
    } else {
      const seleccion = {
        id: i.id,
        denominacion: i.denominacion,
      };

      setItemsSelecionados([...itemSelecionados, seleccion]);
    }
  };

  return (
    <Modal titulo={title} cerrarModal={cerrarModal}>
      <div className="modal-interior">
        {datos &&
          datos.map((i) => (
            <label key={i.id}>
              <input
                type="checkbox"
                checked={isChecked(i)}
                onChange={() => handlePerfilChange(i)}
              />
              {i.denominacion}
            </label>
          ))}
      </div>
    </Modal>
  );
};

export default ModalListaCheck;
