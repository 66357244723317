import { useEffect, useState } from 'react';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Filtros } from './Filtros';
import { CatalogList } from './CatalogList';
import { ActionsTable } from './components/ActionsTable/ActionsTable';
import { BtnIniciarPedido } from './components/BtnPedido/BtnIniciarPedido';
import { CatalogGrid } from './components/CatalogGrid/CatalogGrid';
import { useGlobalStore } from '../../Hooks/useGlobalStore';
import { useURLParams } from './hooks/useURLParams';
import { useCatalog } from './hooks/useCatalog';
import { useClient } from './hooks/useClient';
import { Loading } from './components/Loading/Loading';

import '../css/Catalogo.css';
import '../css/Tablas.css';
import { PRICE_WITH_IVA, useUserConfig } from '../../Hooks/useUserConfig';
import { Switch } from '../Switch/Switch';

export const Catalogo = () => {
  const { searchParams, updateParms } = useURLParams();
  const [pagina, setPagina] = useState(1);
  const [filterSearch, setFilterSearch] = useState(() => {
    const paramSearch = searchParams.get('denominacion');
    return paramSearch ? paramSearch : '';
  });
  const [visiblefiltros, setVisibleFiltros] = useState(false);
  const { data: isMobile } = useGlobalStore('isMobile');
  const { data: dataCatalog, isFetching } = useCatalog();
  const [totalPaginas, setTotalPaginas] = useState(dataCatalog?.paginas || 0);
  const { data: dataClient } = useClient();
  const { setPriceWithIva, userConfig: priceWithIva = false } =
    useUserConfig(PRICE_WITH_IVA);

  useEffect(() => setTotalPaginas(dataCatalog?.paginas), [dataCatalog]);

  useEffect(() => {
    const paramPage = searchParams.get('pagina') || 1;
    const res = paramPage ? Number(paramPage) : 1;
    setPagina(res <= totalPaginas ? res : totalPaginas);
  }, [searchParams, totalPaginas]);

  const updatePage = (page) => {
    setPagina(page);
    updateParms(['pagina', page]);
  };

  const handleSearchInputChange = ({ target }) => {
    const value = target.value.toUpperCase();
    setFilterSearch(value);
    setPagina(1);
    updateParms(['denominacion', value, 'pagina', 1]);
  };

  const sumaPagina = () => pagina < totalPaginas && updatePage(pagina + 1);

  const restaPagina = () => pagina > 1 && updatePage(pagina - 1);

  const toggleFiltros = () => setVisibleFiltros(!visiblefiltros);

  return (
    <div className="grandcontainer__catalogo">
      <div className="encabezado_catalogo">
        <Filtros visible={visiblefiltros} toggleFiltros={toggleFiltros} />
        <div className="search-container">
          <BtnIniciarPedido />
          <input
            type="text"
            id="search-input"
            className="search-input"
            placeholder="Buscar..."
            value={filterSearch}
            onChange={handleSearchInputChange}
          />
          <Switch
            id="withIva"
            type="checkbox"
            checked={priceWithIva}
            onChange={setPriceWithIva}
            className="price-with-iva"
          >
            Precios con IVA
          </Switch>
          <ActionsTable toggleFiltros={toggleFiltros} />
        </div>
      </div>
      {isFetching ? (
        <Loading enabled />
      ) : (
        <div className="catalogosencillo">
          {!isMobile ? (
            <div className="contenedortabla">
              <table className="tabla">
                <thead className="tabla__encabezado">
                  <tr>
                    <th className="tabla__titulo tabla__media codigo">
                      Código
                    </th>
                    <th className="tabla__titulo tabla__media codigo">
                      Sinónimo
                    </th>
                    <th className="tabla__titulo tabla__desc descripcion">
                      Descripcion
                    </th>
                    <th className="tabla__titulo tabla__media stock tabla__num">
                      Stock
                    </th>
                    <th className="tabla__titulo tabla__precio precio tabla__num">
                      Precio
                    </th>
                    {dataClient && (
                      <th className="tabla__titulo cell-actions-table" />
                    )}
                  </tr>
                </thead>
                <tbody>
                  <CatalogList lisArt={dataCatalog?.data || []} />
                </tbody>
              </table>
            </div>
          ) : (
            <CatalogGrid lisArt={dataCatalog?.data || []} isMobile={isMobile} />
          )}
          {!isFetching && (
            <div className="paginacion">
              <button className="paginacion-antes" onClick={restaPagina}>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  size="xl"
                  className="iconleft"
                />
                Anterior
              </button>
              <span className="paginacion-centro">
                {pagina} de {totalPaginas}
              </span>
              <button className="paginacion-despues" onClick={sumaPagina}>
                Siguiente
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xl"
                  className="iconright"
                />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Catalogo;
