export const registerEventViewport = (queryClient) => {
  setStoreIsMobile(window.innerWidth, queryClient);
  window.addEventListener('resize', (event) => {
    setStoreIsMobile(event.currentTarget.innerWidth, queryClient);
  });
};

export const setStoreIsMobile = (width, queryClient) => {
  const isMobile = width <= 768;
  queryClient.setQueryData(['isMobile'], isMobile);
};
