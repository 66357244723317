import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '../UI/Badge/Badge';
import { useFilters } from '../../hooks/useFilters';
import './ActionsTable.css';

export const ActionsTable = ({ toggleFiltros }) => {
  const { filtersCount } = useFilters();

  return (
    <div className="contenedor__filtro">
      <div className="fg-8">
        <p>Filtros</p>
        <label
          htmlFor="toggle"
          className="hamburger filter-icon-container"
          onClick={() => {
            toggleFiltros();
          }}
        >
          <FontAwesomeIcon icon={faFilter} size="xl" />
          {filtersCount && <Badge>{filtersCount}</Badge>}
        </label>
      </div>
    </div>
  );
};
