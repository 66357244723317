import { useEffect, useState } from 'react';
import { useClient } from '../../../hooks/useClient';
import { useGlobalStore } from '../../../../../Hooks/useGlobalStore';
import { ProductCard } from '../ProductCard/ProductCard';
import { ProductRow } from '../ProductRow/ProductRow';
import {
  PRICE_WITH_IVA,
  useUserConfig,
} from '../../../../../Hooks/useUserConfig';

export const Product = ({ articulo }) => {
  const { priceTypeByClient, data } = useClient();
  const [prices, setPrices] = useState();
  const { data: isMobile } = useGlobalStore('isMobile');
  const { userConfig: priceWithIva } = useUserConfig(PRICE_WITH_IVA);

  const { precios, Stock, moneda } = articulo;

  useEffect(() => {
    const currentPrices =
      precios.find((i) => i.id === priceTypeByClient) || precios?.[0];
    setPrices(currentPrices);
  }, [articulo, data, moneda, precios, priceTypeByClient]);

  const priceToDisplay = priceWithIva
    ? prices?.monto_con_iva
    : prices?.monto_neto;

  const stockUnitario = Stock?.[0]?.cantidad ?? 0;
  const showActions = data?.denominacion;

  const props = {
    articulo,
    stockUnitario,
    showActions,
    precios: prices,
    priceToDisplay,
  };

  return isMobile ? <ProductCard {...props} /> : <ProductRow {...props} />;
};
