import { useState } from 'react';

const useModal = () => {
  const [active, setActive] = useState(false);

  const toggle = () => {
    setActive((prevActive) => !prevActive); // Toggle el valor actual de active
  };

  return { active, toggle };
};

export default useModal;
