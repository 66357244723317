import React, { useState } from 'react';
import Acordeon from './Acordeon';
import { formattedPrices } from '../../helpers/formatter';
import '../css/Nico.css';
import '../css/Articulo.css';
import '../css/Styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export const Articulo = ({ articulo }) => {
  const [modalArtVisible, setModalArtVisible] = useState(false);
  const { denominacion } = articulo;
  return (
    <>
      {denominacion && (
        <>
          <button
            className="articulo-button__open_modal"
            type="button"
            onClick={() => setModalArtVisible(true)}
          >
            <span>{denominacion}</span>
          </button>
          {modalArtVisible && (
            <ArticleContent
              articulo={articulo}
              closeModal={setModalArtVisible}
            />
          )}
        </>
      )}
    </>
  );
};

const ArticleContent = ({ articulo, closeModal }) => {
  const {
    precios,
    estado = 'Sin Estado',
    Stock: stock,
    fecha_modificacion,
    codigo_mg,
    denominacion,
    moneda,
    imagenes,
  } = articulo;

  const fechaModif = fecha_modificacion || 'Sin Fecha de Modificación';
  const estadoArt = estado === 'Activo' ? '' : estado;
  const imageArt = imagenes?.[0] ?? '';

  const cerrarModalArt = () => closeModal(false);

  return (
    <div className="modal-art" id="modal-art">
      <div className="modal-content-global">
        <div className="contenedor-modalcabecera">
          <div className="contenedor-cruz-y-logo">
            <div className="contenedor-cerrar">
              <FontAwesomeIcon
                icon={faXmark}
                size="xl"
                onClick={cerrarModalArt}
              />
            </div>
            <div className="contenedor-logo">
              <img
                className="modal-imagen-logo"
                src={imageArt || 'imagen-por-defecto.jpg'}
                alt="Imagen del Articulo"
                height={imageArt ? '200px' : '25px'}
              />
            </div>
          </div>
          <hr className="modal-linea" />
          <h2 className="modal-titulo">{denominacion}</h2>

          <Acordeon titulo="Stock" datos={stock} />
          <Acordeon titulo="Precios" datos={formattedPrices(precios, moneda)} />
          <div className="modal-codigo">
            <span className="">Codigo:</span>
            <span>{codigo_mg}</span>
          </div>
          <div className="modal-articulo-footer">
            <h3 className="modal-fecha">
              {`Precio Actualizado: ${fechaModif}`}
            </h3>
            <span className="modal-estado"> {estadoArt} </span>
          </div>
        </div>
      </div>
      <div className="modal-background" onClick={cerrarModalArt}></div>
    </div>
  );
};
