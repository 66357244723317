import { useContext, useEffect, useState } from 'react';
import { back } from '../../../helpers/dataServer';
import { UserContext } from '../../auth/context/UserContext';

export const useCliente = (filter) => {
  const [filteredClientes, setFilteredClientes] = useState([]);

  const { dataUser } = useContext(UserContext);

  const { token } = dataUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `${back}/clientes`;
        const dataFetch = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', authorization: token },
        };

        if (filter) {
          endpoint += `?denominacion=${filter}`;
        }

        const response = await fetch(endpoint, dataFetch);
        const result = await response.json();

        if (response.ok) {
          setFilteredClientes(result.errors.data);
        } else {
          console.error(result.errors.msg);
        }
      } catch (error) {
        console.error('Error al obtener los clientes:', error);
      }
    };

    fetchData();
  }, [filter]);

  return filteredClientes;
};

// import { useEffect } from 'react';
// import { back } from '../../../helpers/dataServer';

// export const useCliente = useEffect(() => {
//   const fetchData = async () => {
//     try {
//       let endpoint = `${back}/clientes`;
//       const dataFetch = {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' }
//       };

//       console.log('filter useEffect');
//       console.log(filter);

//       if (filter) {
//         endpoint += '?denominacion=' + filter;
//       }

//       console.log('endpoint', endpoint);
//       const response = await fetch(endpoint, dataFetch);
//       const result = await response.json();
//       console.log('response', response);
//       console.log('result', result);

//       if (response.ok) {
//         setFilteredClientes(result.errors.data);
//       } else {
//         console.error(result.errors.msg);
//       }
//     } catch (error) {
//       console.error('Error al obtener los clientes:', error);
//     }
//   };

//   fetchData();
// }, [filter]);
