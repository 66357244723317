import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useURLParams = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateParms = useMemo(
    () => (arrayParams) => {
      const newSearchParams = new URLSearchParams(searchParams);

      for (let i = 0; i < arrayParams.length; i += 2) {
        const key = arrayParams[i];
        const value = arrayParams[i + 1];
        value ? newSearchParams.set(key, value) : newSearchParams.delete(key);
      }

      navigate(`/catalog/catalogo?${newSearchParams.toString()}`, {
        replace: true,
      });
      setSearchParams(newSearchParams);
    },
    [navigate, searchParams, setSearchParams]
  );

  return {
    searchParams,
    updateParms,
  };
};
