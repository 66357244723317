import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imgArticulo from '../../../noimg.svg';
import removeItems from '../../assets/removeItems.svg';
import addItems from '../../assets/addItems.svg';
import { useEffect, useState } from 'react';
import { useShopingCart } from '../../hooks/useShopingCart';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../cart/Carro.css';
import { formatPrecio } from '../../hooks/useFormat';
import { RowsItems } from './RowsItems';
// import { GetArt } from "./services/GetArt";

export const ItemsCart = () => {
  const { getItems } = useShopingCart();

  const items = getItems();

  const itemCart = items.length > 0 ? items : [];

  return (
    <div className="contenedorTarjetas">
      {itemCart.map((item) => (
        <RowsItems item={item} />
      ))}
    </div>
  );
};
