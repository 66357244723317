import React, { useState, useRef } from 'react';
import '../css/Nico.css';

export const PermisosModal = ({ isOpen, closeModal, handleSave }) => (
  <>
    {/* <div className= {`modal ${isOpen && 'modal-open'}`}> */}
    <div
      className={isOpen ? 'modal modal-open' : ''}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <article className="modal-content">
        <article className="modal-inside">
          <span id="closeModal" className="close" onClick={closeModal}>
            &times;
          </span>
          <h2>Alta de Permisos</h2>
          <br />
          <div className="info-perfil">
            <label>Permiso: </label>
            <input type="text" />
            <input
              type="button"
              onClick={handleSave}
              value="Grabar"
              className="btn_alta_permiso"
            />
          </div>
        </article>
      </article>
    </div>
  </>
);
