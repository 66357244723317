import { useEffect, useState } from 'react';
import AdministrarCuitJson from '../../database/administrarCuit.json';

export const AdministrarCuit = ({ titulo }) => {
  const [AdminCuit, setAdminCuit] = useState([]);

  useEffect(() => {
    setAdminCuit(AdministrarCuitJson);
  }, []);

  const handleAddRow = () => {
    const newRow = {};
    setAdminCuit([...AdminCuit, newRow]);
  };

  // FIXME - AdminCuit está declarado en muchos lugares.
  const handleEditRow = (AdminCuit, cuit, empresa, usuario) => {
    const updatedData = AdminCuit.map((AdminCuit) =>
      AdminCuit.AdminCuit === AdminCuit
        ? {
            ...AdminCuit,
            cuit: `${AdminCuit.cuit}`,
            empresa: `${AdminCuit.empresa}`,
            usuario: `${AdminCuit.usuario}`
          }
        : AdminCuit
    );
    setAdminCuit(updatedData);
  };
  return (
    <main className="main">
      <h3>{titulo}</h3>
      <div className="search-container">
        <input
          type="text"
          id="search-input"
          className="search-input"
          placeholder="Buscar..."
        />
        <button onClick="search()" className="search-button">
          <i className="fas fa-search search-icon" />
        </button>
      </div>
      <div>
        <table className="tabla">
          <thead className="tabla__encabezado">
            <tr>
              <th className="tabla__titulo tabla__media"> Cuit </th>
              <th className="tabla__titulo"> Empresa </th>
              <th className="tabla__titulo"> Usuario </th>
              <th className="tabla__titulo tabla__importe">
                <button
                  onClick={handleAddRow}
                  className="tabla__encabezado-btn"
                >
                  <i className="fa-regular fa-square-plus" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody className="tabla__contenido">
            {AdminCuit.map(({ index, cuit, empresa, usuario }) => (
              <tr key={`${index}${cuit}`}>
                <td className="tabla__contenido tabla__num" key={cuit}>
                  {cuit}
                </td>
                <td className="tabla__contenido tabla__text" key={cuit}>
                  {empresa}
                </td>
                <td className="tabla__contenido tabla__text" key={cuit}>
                  {usuario}
                </td>
                <td className="tabla__contenido tabla__ico">
                  <button
                    className="tabla__contenido-btn"
                    onClick={() =>
                      handleEditRow(cuit.cuit, cuit.empresa, cuit.usuario)
                    }
                  >
                    <i
                      className="fa-solid fa-file-pen"
                      style={{ color: '#808080' }}
                    />
                  </button>
                  <button type="button" className="tabla__contenido-btn">
                    <i
                      className="fas fa-trash-alt delete-icon"
                      style={{ color: '#808080' }}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

