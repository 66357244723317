import { useQuery } from '@tanstack/react-query';
import { API } from '../../../helpers/API';
import { useSearchParams } from 'react-router-dom';

const QUERY_KEY = 'properties';

const fetchData = (searchParams) => async () => {
  const param = searchParams.get('denominacion');
  const newSearchParams = new URLSearchParams();
  if (param) {
    newSearchParams.append('denominacion', param);
  }
  const res = await API.GET(
    `articulos/listPropieties?${newSearchParams.toString()}`
  );
  return res?.errors.data;
};

export const usePropieties = () => {
  const [searchParams] = useSearchParams();
  const param = searchParams.get('denominacion') || '';

  const queryProperties = useQuery({
    queryKey: [QUERY_KEY, param],
    queryFn: fetchData(searchParams),
    staleTime: 1000 * 5,
    gcTime: 1000 * 10,
  });

  return { ...queryProperties };
};
