import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Importar SweetAlert2
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { back } from '../../helpers/dataServer';
import isPasswordValid from './funciones/PasswordValidation';
import isPasswordMatch from './funciones/ConfirmPasswordMatch';

export const Reestablecer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const location = useLocation();
  const [tokenValid, setTokenValid] = useState(false);

  const [showPasswordClave, setShowPasswordClave] = useState(false);
  const [showPasswordConfirmar, setShowPasswordConfirmar] = useState(false);

  const [timeLeft, setTimeLeft] = useState(0); // Inicialmente, no se muestra el contador.

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    // Verificar el token en el lado del cliente
    try {
      const decoded = jwt_decode(token);
      if (decoded.email) {
        console.log('decoded', decoded);
        const fechahora = new Date();
        const { exp } = decoded;
        console.log(fechahora.getTime().toString());

        const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos

        if (currentTime >= exp * 1000) {
          // Mostrar una alerta SweetAlert2 de tiempo expirado
          Swal.fire({
            title: 'Token Expirado',
            text: 'Deberá solicitar nuevamente la solicitud de reestablecimiento',
            icon: 'error',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/auth/login');
            }
          });
          setTokenValid(false);
        } else {
          setTokenValid(true);
          setEmail(decoded.email);
          // Calcular el tiempo restante en segundos
          const timeRemaining = exp - currentTime;
          setTimeLeft(timeRemaining);

          // Actualizar el contador cada segundo
          const timer = setInterval(() => {
            if (timeLeft > 0) {
              setTimeLeft(timeLeft - 1);
            } else {
              clearInterval(timer);
            }
          }, 1000);
        }
      } else {
        setTokenValid(false);
      }
    } catch (error) {
      console.error('Token no válido', error);
      setTokenValid(false);
    }
  }, []);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validar contraseñas y si coinciden
    if (
      !isPasswordValid(password) ||
      !isPasswordMatch(password, confirmPassword)
    ) {
      // Manejar la lógica de errores o mostrar un mensaje de error
      console.log('Contraseña no válida o no coinciden.');
      return;
    }

    // Datos a enviar al servidor
    const userData = {
      email,
      clave: password, // Usar la contraseña validada
    };

    fetch(`${back}/auth/updatePassByEmail/${email}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData), // Convertir el objeto a JSON
    })
      .then((result) => result.json())
      .then((response) => {
        setErrors(response.errors);
        console.log(JSON.stringify(response.errors));

        if ((response = 200)) {
          // Mostrar una alerta SweetAlert2
          Swal.fire({
            title: 'Contraseña Reestablecida',
            text: 'La contraseña se ha reestablecido con éxito.',
            icon: 'success',
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirigir al usuario a la página de inicio de sesión después de restablecer la contraseña
              navigate('/auth/login');
            }
          });
        }
      })
      .catch((error) => {
        // Manejar errores de red u otros errores aquí
        console.error('Error en la solicitud:', error);
        alert('Error en la solicitud:', error);
      });
  };

  const togglePasswordVisibilityClave = () => {
    setShowPasswordClave(!showPasswordClave);
  };

  const togglePasswordVisibilityConfirmar = () => {
    setShowPasswordConfirmar(!showPasswordConfirmar);
  };

  const formatTimeLeft = () => {
    const horas = Math.floor(timeLeft / 3600);
    const minutos = Math.floor((timeLeft % 3600) / 60);
    const segundos = timeLeft % 60;

    const hoursStr = horas > 0 ? `${horas} hora${horas !== 1 ? 's' : ''}` : '';
    const minutesStr =
      minutos > 0 ? `${minutos} minuto${minutos !== 1 ? 's' : ''}` : '';
    const secondsStr =
      segundos > 0 ? `${segundos} segundo${segundos !== 1 ? 's' : ''}` : '';

    const timeStrings = [hoursStr, minutesStr, secondsStr].filter(
      (str) => str !== ''
    );

    return timeStrings.join(' ');
  };

  return (
    <div className="granContenedorLogin">
      <div className="contenedor">
        {!tokenValid && <h2> Token inválido </h2>}
        {tokenValid && (
          <div className="formulario">
            <div className="contenedorLogo">
              <img src="/logoms.png" alt="logo" className="logoLogin" />
              <span className="logoTitle">MACROSISTEMAS S.R.L.</span>
            </div>
            <div className="time-remaining">
              <p className="tiempo-message">
                Tiempo restante: {formatTimeLeft()}
              </p>
            </div>
            <h2>Restablecer Contraseña-PRUEBA</h2>
            <form onSubmit={handleSubmit}>
              {/* <label htmlFor="email">Correo electrónico:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            required
          /> */}
              <div className="">Correo electrónico:</div>
              <h3 className="email_reestablecer">{email}</h3>

              {errors && errors.email && (
                <p className="warning">{errors.email.msg}</p>
              )}

              <label htmlFor="clave">Nueva Contraseña:</label>
              <div className="passinput">
                <input
                  type={showPasswordClave ? 'text' : 'password'}
                  id="clave"
                  name="clave"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  className={!isPasswordValid(password) ? 'error' : ''}
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibilityClave}
                >
                  <FontAwesomeIcon
                    icon={showPasswordClave ? faEyeSlash : faEye}
                    aria-hidden="true"
                    className="ojo"
                  />
                </button>
              </div>
              {errors && errors.clave && (
                <p className="warning">{errors.clave.msg}</p>
              )}
              {!isPasswordValid(password) && (
                <div className="error-message">
                  Clave de 8 caracteres mínimo, 1 caracter especial, 1 número, 1
                  letra mayúscula y una minúscula.
                </div>
              )}

              <label htmlFor="confirmar-clave">Confirmar Contraseña:</label>
              <div className="passinput">
                <input
                  type={showPasswordConfirmar ? 'text' : 'password'}
                  id="confirmar-clave"
                  name="confirmar-clave"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  className={
                    !isPasswordMatch(password, confirmPassword) ? 'error' : ''
                  }
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibilityConfirmar}
                >
                  <FontAwesomeIcon
                    icon={showPasswordConfirmar ? faEyeSlash : faEye}
                    aria-hidden="true"
                    className="ojo"
                  />
                </button>
              </div>
              {errors && errors.confirmPassword && (
                <p className="warning">{errors.confirmPassword.msg}</p>
              )}
              {!isPasswordMatch(password, confirmPassword) && (
                <div className="error-message-pass">
                  Las contraseñas no coinciden.
                </div>
              )}
              <div className="finalform">
                <input
                  type="submit"
                  value="Restablecer"
                  disabled={
                    !isPasswordValid(password) ||
                    !isPasswordMatch(password, confirmPassword)
                  }
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
