export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const calculateTotal = (pedido) => {
  const total = pedido.Comprobantes_items.reduce(
    (acc, item) => acc + parseFloat(item.precio) * parseFloat(item.cantidad),
    0
  );
  return total.toFixed(2);
};

export const formatPrecio = (precio = 0, cantidad = 0) =>
  (parseFloat(precio) * parseFloat(cantidad)).toFixed(2);

export const formatSucursal = (sucursal) => {
  return sucursal.toString().padStart(4, '0');
};

export const formatNumero = (numero) => {
  return numero.toString().padStart(8, '0');
};
