import { Navigate, Route, Routes } from 'react-router-dom';
import { SecurityRoutes } from '../security/Routes/SecurityRoutes';
import { CatalogRoutes } from '../catalogo/Routes/CatalogRoutes';
import MainPage from '../main/pages/MainPage';
import { AuthRoutes } from '../auth/Routes/AuthRoutes';
import Carro from '../ShoppingCart/components/cart/Carro';
import PrivateRoute from '../auth/Routes/PrivateRoute';
import PublicRoute from '../auth/Routes/PublicRoute';
import EditPerfil from '../security/EditPerfil';
import { ContactRoutes } from '../../Domains/Contact/Routes/ContactRoutes';

export const AppRouter = () => (
  <Routes>
    <Route
      path="auth/*"
      element={
        <PublicRoute>
          <AuthRoutes />
        </PublicRoute>
      }
    />

    <Route
      path="security/*"
      element={
        <PrivateRoute>
          <MainPage>
            <SecurityRoutes />
          </MainPage>
        </PrivateRoute>
      }
    />

    <Route
      path="catalog/*"
      element={
        <PrivateRoute>
          <MainPage>
            <CatalogRoutes />
          </MainPage>
        </PrivateRoute>
      }
    />

    <Route
      path="productos/*"
      element={
        <PrivateRoute>
          <MainPage>
            <Carro />
          </MainPage>
        </PrivateRoute>
      }
    />

    <Route
      path="editperfil/*"
      element={
        <PrivateRoute>
          <MainPage>
            <EditPerfil />
          </MainPage>
        </PrivateRoute>
      }
    />

    <Route
      path="/*"
      element={
        <PrivateRoute>
          <MainPage>
            <ContactRoutes />
          </MainPage>
        </PrivateRoute>
      }
    />

    <Route path="/" element={<Navigate to="auth/login" />} />
  </Routes>
);
