import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Loading.css';

export const Loading = ({ enabled = false }) => {
  if (!enabled) return null;

  return (
    <div className="loading-spinner">
      <FontAwesomeIcon icon={faSpinner} spin size="5x" />
    </div>
  );
};
