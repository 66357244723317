import React, { useEffect, useState, useContext } from 'react';
import { UserContext, useUser } from '../auth/context/UserContext';
import '../css/Catalogo.css';
import '../css/Tablas.css';
import { back } from '../../helpers/dataServer';
import { NavFiltros } from './NavFiltros';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faFilter,
  faFilterCircleXmark,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import imagen from '../noimg.svg';
import BotonesVista from './BotonesVista';
import Filtros from './Filtros';
import Articulo from '../Productos/Articulo';
import { ActionsTable } from './components/ActionsTable/ActionsTable';

const Catalogo = () => {
  const [lisArt, setListArt] = useState([]);
  const [propiedades, setPropiedades] = useState([]);
  const [TotalPaginas, setTotalPaginas] = useState(0);
  const [pagina, setPagina] = useState(1);
  const [filter, setFilter] = useState('');
  const [cantidadPorArticulo, setCantidadPorArticulo] = useState({});
  const [visiblefiltros, setVisibleFiltros] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const { dataUser } = useContext(UserContext);
  const [denominacionSeleccionada, setDenominacionSeleccionada] = useState();
  const [filtrosActivos, setFiltrosActivos] = useState(false);
  // const [filtroSeleccionado, setfiltroSeleccionado] = useState([]);

  const [total, setTotal] = useState(0);

  const [dataPerfil, setDataPerfil] = useState(null);

  const { token } = dataUser;

  const headers = new Headers();
  headers.set('authorization', token);

  const endpoint = `${back}/articulos/list`;

  const dataFetch = {
    method: 'POST',
    headers,
  };

  useEffect(() => {
    setIsLoading(true);

    // endpoint = `${back}/articulos/list`
    dataFetch.method = 'POST';
    dataFetch.headers = {
      'Content-Type': 'application/json',
      authorization: token,
    };
    dataFetch.body = JSON.stringify({
      busqueda: denominacionSeleccionada,
      denominacion: filter,
      pagina,
      perfiles: dataUser.perfiles,
    });

    fetch(endpoint, dataFetch)
      .then((response) => response.json())
      .then((res) => {
        setListArt(res.errors.data);
        setPropiedades(res.errors.propiedades);
        setTotalPaginas(res.errors.paginas);
        setIsLoading(false);
        setDataPerfil(dataUser.perfil);
      })
      .catch((err) => {
        console.warn(err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const endpoint = `${back}/articulos/list`;
    dataFetch.method = 'POST';
    dataFetch.headers = {
      'Content-Type': 'application/json',
      authorization: token,
    };
    dataFetch.body = JSON.stringify({
      busqueda: denominacionSeleccionada,
      denominacion: filter,
      pagina,
      perfiles: dataUser.perfiles,
    });

    fetch(endpoint, dataFetch)
      .then((response) => response.json())
      .then((res) => {
        setListArt(res.errors.data);
        setPropiedades(res.errors.propiedades);
        setTotalPaginas(res.errors.paginas);
        setIsLoading(false);
        setDataPerfil(dataUser.perfil);
      })
      .catch((err) => {
        console.warn(err);
        setIsLoading(false);
      });
  }, [pagina, filter, denominacionSeleccionada]);

  useEffect(() => {
    setIsLoading(true);
    let endpoint =
      filter.length > 0
        ? `${back}/articulos/list?pagina=${pagina}&denominacion=${filter}`
        : `${back}/articulos/list?pagina=${pagina}`;

    if (denominacionSeleccionada) {
      endpoint = `${back}/articulos/list`;
      setFiltrosActivos(true);
      dataFetch.method = 'POST';
      dataFetch.headers = { 'Content-Type': 'application/json' };
      dataFetch.body = JSON.stringify({
        busqueda: denominacionSeleccionada,
        denominacion: filter,
        pagina,
        perfiles: dataUser.perfiles,
      });
    } else {
      setFiltrosActivos(false);
    }

    fetch(endpoint, dataFetch)
      .then((response) => response.json())
      .then((res) => {
        setListArt(res.errors.data);
        setPropiedades(res.errors.propiedades);
        setTotalPaginas(res.errors.paginas);
        setIsLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        setIsLoading(false);
      });
  }, [pagina, filter, denominacionSeleccionada]);

  const handleSearchInputChange = (e) => {
    setFilter(e.target.value.toUpperCase());
  };

  const sumaPagina = () => {
    if (pagina < TotalPaginas) {
      setPagina(pagina + 1);
    }
  };

  const restaPagina = () => {
    if (pagina > 1) {
      setPagina(pagina - 1);
    }
  };

  const toggleFiltros = () => {
    setVisibleFiltros(!visiblefiltros);
    // if (visiblefiltros) {
    //   setDenominacionSeleccionada(''); // Cambiar a setDenominacionSeleccionada('')
    // }
  };

  const limpiarFiltro = () => {
    setDenominacionSeleccionada('');
    setPagina(1);
  };

  const hayFiltrosActivos = filter !== '' || denominacionSeleccionada !== '';
  const arrayDenominacion = denominacionSeleccionada;

  const agruparPropiedades = (denominaciones) => {
    const propiedadesAgrupadas = denominaciones.reduce((agrupado, item) => {
      if (!agrupado[item.propiedad]) {
        agrupado[item.propiedad] = [];
      }
      agrupado[item.propiedad].push(item.denominacion);
      return agrupado;
    }, {});

    return Object.entries(propiedadesAgrupadas)
      .map(
        ([propiedad, denominaciones]) =>
          `${propiedad}: ${denominaciones.join(' - ')}`
      )
      .join('; ');
  };

  // const filtroSeleccionado = (arrayDenominacion.length > 0) ? `${agruparPropiedades(arrayDenominacion)}` : '';
  const filtroSeleccionado =
    arrayDenominacion?.length > 0
      ? `${agruparPropiedades(arrayDenominacion)}`
      : '';

  console.log(lisArt);

  return (
    <div className="grandcontainer__catalogo">
      <div className="encabezado_catalogo">
        <Filtros
          propiedades={propiedades}
          visible={visiblefiltros}
          toogleVisible={setVisibleFiltros}
          setDenominacionSeleccionada={setDenominacionSeleccionada}
          SeteaFiltros={setFilter}
          dataFiltros={filter}
          limpiarFiltro={limpiarFiltro} // Nueva prop para limpiar el filtro
          toggleFiltros={toggleFiltros}
        />
        <NavFiltros />
        <div className="search-container">
          <input
            type="text"
            id="search-input"
            className="search-input"
            placeholder="Buscar..."
            value={filter}
            onChange={handleSearchInputChange}
          />
          <ActionsTable
            filtrosActivos={filtrosActivos}
            limpiarFiltro={limpiarFiltro}
            toggleFiltros={toggleFiltros}
          />
        </div>
      </div>
      {filtroSeleccionado}
    </div>
  );
};

export default Catalogo;

// import React, { useEffect, useState, useContext } from 'react';
// import { UserContext, useUser } from '../auth/context/UserContext';
// import '../css/Catalogo.css';
// import '../css/Tablas.css';
// import { back } from '../../helpers/dataServer';
// import { NavFiltros } from './NavFiltros';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight, faFilter, faFilterCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
// import proplangatos from '../../public/proplan-gatos.png';
// import imagen from '../noimg.svg';
// import BotonesVista from './BotonesVista';
// import Filtros from './Filtros';

// const Catalogo = () => {
//   const [lisArt, setListArt] = useState([]);
//   const [propiedades, setPropiedades] = useState([]);
//   const [TotalPaginas, setTotalPaginas] = useState(0);
//   const [pagina, setPagina] = useState(1);
//   const [filter, setFilter] = useState('');
//   const [cantidadPorArticulo, setCantidadPorArticulo] = useState({});
//   const [visiblefiltros, setVisibleFiltros] = useState(false);

//   const [isLoading, setIsLoading] = useState(true);
//   const { dataUser } = useContext(UserContext);
//   const [denominacionSeleccionada, setDenominacionSeleccionada] = useState();
//   const [filtrosActivos, setFiltrosActivos] = useState(false);
//   //const [filtroSeleccionado, setfiltroSeleccionado] = useState([]);

//   const token = dataUser.token;

//   const headers = new Headers();
//   headers.set('authorization', token);

//   useEffect(() => {
//     setIsLoading(true);
//     fetch(`${back}/articulos/list?pagina=${pagina}`, {
//       method: 'GET',
//       headers: headers,
//     })
//       .then(response => response.json())
//       .then(res => {
//         setListArt(res.errors.data);
//         setPropiedades(res.errors.propiedades);
//         setTotalPaginas(res.errors.paginas);
//         setIsLoading(false);
//       })
//       .catch(err => {
//         console.warn(err);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {

//     const dataFetch = {
//       method : 'GET',
//       headers: headers
//     }

//     setIsLoading(true);
//     let endpoint =  filter.length > 0 ?  `${back}/articulos/list?pagina=${pagina}&denominacion=${filter}`:`${back}/articulos/list?pagina=${pagina}`

//     if (denominacionSeleccionada) {
//       endpoint = `${back}/articulos/list`
//       setFiltrosActivos(true)
//       dataFetch.method = 'POST'
//       dataFetch.headers = { 'Content-Type': "application/json"}
//       dataFetch.body = JSON.stringify({
//         busqueda: denominacionSeleccionada,
//         denominacion: filter,
//         pagina
//       })
//     }else{
//       setFiltrosActivos(false)
//     }

//     fetch(endpoint, dataFetch)
//       .then(response => response.json())
//       .then(res => {
//         setListArt(res.errors.data);
//         setPropiedades(res.errors.propiedades);
//         setTotalPaginas(res.errors.paginas);
//         setIsLoading(false);
//       })
//       .catch(err => {
//         console.warn(err);
//         setIsLoading(false);
//       });
//   }, [pagina,filter,denominacionSeleccionada]);

//   const handleSearchInputChange = e => {setFilter(e.target.value.toUpperCase());  };

//   const sumaPagina = () => {
//     if (pagina < TotalPaginas) {
//       setPagina(pagina + 1);
//     }
//   };

//   const restaPagina = () => {
//     if (pagina > 1) {
//       setPagina(pagina - 1);
//     }
//   };

//   const toggleFiltros = () => {

//     setVisibleFiltros(!visiblefiltros);
//     // if (visiblefiltros) {
//     //   setDenominacionSeleccionada(''); // Cambiar a setDenominacionSeleccionada('')
//     // }
//   };

//   const limpiarFiltro = () => {
//     setDenominacionSeleccionada('')
//     setPagina(1);
//    };

//    const hayFiltrosActivos = filter !== '' || denominacionSeleccionada !== '';
//   const arrayDenominacion = denominacionSeleccionada

//   const agruparPropiedades = (denominaciones) => {
//     const propiedadesAgrupadas = denominaciones.reduce((agrupado, item) => {
//       if (!agrupado[item.propiedad]) {
//         agrupado[item.propiedad] = [];
//       }
//       agrupado[item.propiedad].push(item.denominacion);
//       return agrupado;
//     }, {});

//     return Object.entries(propiedadesAgrupadas)
//       .map(([propiedad, denominaciones]) =>`${propiedad}: ${denominaciones.join(' - ')}`)
//       .join('; ');
//   };

//   //const filtroSeleccionado = (arrayDenominacion.length > 0) ? `${agruparPropiedades(arrayDenominacion)}` : '';
//   const filtroSeleccionado = (arrayDenominacion?.length > 0) ? `${agruparPropiedades(arrayDenominacion)}` : '';

//   return (
//     <>
//       <div className="grandcontainer__catalogo">
//         <div className="encabezado_catalogo">
//           <Filtros
//             propiedades={propiedades}
//             visible={visiblefiltros}
//             toogleVisible={setVisibleFiltros}
//             setDenominacionSeleccionada={setDenominacionSeleccionada}
//             SeteaFiltros={setFilter}
//             dataFiltros={filter}
//             limpiarFiltro={limpiarFiltro} // Nueva prop para limpiar el filtro
//             toggleFiltros={toggleFiltros}
//           />
//           <NavFiltros />
//           <div className="search-container">
//             <input
//               type="text"
//               id="search-input"
//               className="search-input"
//               placeholder="Buscar..."
//               value={filter}
//               onChange={handleSearchInputChange}
//             />
//             <div className="contenedor__filtro">
//               <p>Filtros</p>
//               <label htmlFor="toggle" className="hamburger" onClick={() => { toggleFiltros(); }}>
//                 <FontAwesomeIcon icon={filtrosActivos ? null : faFilter} size="xl" />
//               </label>

//               {filtrosActivos && (
//                 <label className="hamburger" onClick={limpiarFiltro}>
//                   <FontAwesomeIcon icon={faFilterCircleXmark} size="xl" />
//                 </label>
//               )}
//               <div className="contenedor__vista">
//                 {/* <div className="opciones__vista"> */}
//                 <p>Vista</p>
//                 <BotonesVista />
//                 {/* </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//         {filtroSeleccionado}
//         <div className="catalogocuadricula">
//           <div className="contenedorcuadricula">
//             {isLoading ? (
//               <div className="loading-spinner">
//                 <FontAwesomeIcon icon={faSpinner} spin size="5x" />
//               </div>
//             ) : (
//               lisArt &&
//               lisArt.map(articulo => {
//                 const maxLength = 54;
//                 const display = articulo.denominacion.toUpperCase().includes(filter);
//                 const precioUnitario = articulo.precios ? articulo.precios[0].precio : 0;
//                 return (
//                   <tr key={articulo.codigo_mg} style={{ display: display ? '' : 'none' }}>
//                     <div id="catalogo" key={articulo.id}>
//                       <ul className="lista">
//                         <li className="itemcuad">
//                           <div className='contenedor-imagencatalogcuad'>
//                             <img className="img-producto" src={articulo.imagenes ? articulo.imagenes[0] : imagen} alt="Imagen del Articulo" height={articulo.imagenes ? "250px" : "25px"} width={articulo.imagenes ? "250px" : "25px"} />
//                           </div>
//                           <div className="contenedor-linea">
//                             <hr className="linea" />
//                           </div>
//                           <div className="info-producto">
//                             {/*<td className="cod-producto">{articulo.codigo_mg}</td>*/}
//                             {articulo.denominacion.length > maxLength
//                               ? articulo.denominacion.substring(0, maxLength) + "..."
//                               : articulo.denominacion}
//                             {/*<Articulo
//                         denominacion={articulo.denominacion}
//                       />*/}
//                           </div>
//                           {/* <div className="stock-producto">
//                           <p>Stock</p>
//                           <td className="s-prod">{articulo.Stock ? articulo.Stock : 0}</td>
//                         </div> */}
//                           <div className="stock-producto">
//                             <p className='s-prod' style={{ color: articulo.Stock ? '#0056b3' : 'red' }}>
//                               Stock: {articulo.Stock ? articulo.Stock : 0} </p>
//                           </div>
//                           {/* <div>
//                           <i class="fa-solid fa-minus" alt="Quitar del carrito" onClick={() => eliminarDelCarritoDesdeCatalogo(articulo)}></i>
//                           <input type="text" className="tabla__cant" placeholder="0" value={cantidadPorArticulo[articulo.id] || 0} />
//                           <i class="fa-solid fa-plus" alt="Añadir al carrito" onClick={() => agregarAlCarritoDesdeCatalogo(articulo)}></i>
//                         </div> */}
//                           <div className="precio-producto">
//                             <p>$ </p>
//                             <td className="precio-u">{precioUnitario || 0}</td>
//                           </div>
//                           {/* <div className="btn-group"> se comenta boton añadir carrito
//                           <button className="btn btn-primary btn-sm btn-agregarcarrito"><i className="fa fa-shopping-cart"></i> Agregar al Carrito</button>
//                         </div> */}
//                         </li>
//                       </ul>
//                     </div>
//                   </tr>
//                 )
//               }))}

//           </div>
//           <div className='paginacion'>
//             <button className="paginacion-antes" onClick={restaPagina}><FontAwesomeIcon
//               icon={faChevronLeft}
//               size='xl'
//               className='iconleft'
//             />Anterior</button>
//             <p> Pagina {pagina} de {TotalPaginas}  </p>
//             <button className="paginacion-despues" onClick={sumaPagina}>Siguiente<FontAwesomeIcon
//               icon={faChevronRight}
//               size='xl'
//               className='iconright'
//             /></button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Catalogo;
