import { useClient } from '../../../catalogo/hooks/useClient';
import { useShopingCart } from '../../hooks/useShopingCart';
import './ResumeCard.css';
import { ResumeCardChild } from './ResumeCardChild';

export const ResumeCard = () => {
  const { getTotalPrices, currencyCart } = useShopingCart();
  const { data: dataClient } = useClient();

  const { monto_neto, descuento, precio_iva, monto_con_iva } = getTotalPrices();
  const { porc_descuento } = dataClient || {};

  console.log(monto_neto, descuento, precio_iva, monto_con_iva);

  //ARMADO DE PEDIDO
  return (
    <>
      <ResumeCardChild
        monto_con_iva={monto_con_iva}
        monto_neto={monto_neto}
        precio_iva={precio_iva}
        porc_descuento={porc_descuento}
        descuento={descuento}
      />
    </>
  );
};
