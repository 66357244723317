import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { back } from '../../helpers/dataServer';
import '../css/Acordeon.css';
import { UserContext } from '../auth/context/UserContext';
import save from './assets/save.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

export const ItemSelect = ({ usuario, abierto, toggleAcordeon }) => {
  const [usuarioPerfil, setUsuarioPerfil] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [perfilesDesmarcados, setPerfilesDesmarcados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dataUser } = useContext(UserContext);

  const { token } = dataUser;

  useEffect(() => {
    fetch(`${back}/perfiles/listperfiles`, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => setPerfiles(data.data))
      .catch((error) => {
        console.error('Error al obtener la lista de perfiles:', error);
      });

    const perfilesGuardados = [];
    usuario.perfiles.forEach((i) => {
      perfilesGuardados.push(i.id_perfil);
    });
    setUsuarioPerfil(perfilesGuardados);
  }, [token, usuario.perfiles]);

  const handleCheckboxChange = (perfilId) => {
    const newUsuarioPerfil = [...usuarioPerfil];
    const index = newUsuarioPerfil.indexOf(perfilId);
    if (index === -1) {
      newUsuarioPerfil.push(perfilId);
    } else {
      newUsuarioPerfil.splice(index, 1);
      setPerfilesDesmarcados((prevState) => [...prevState, perfilId]);
    }
    setUsuarioPerfil(newUsuarioPerfil);
  };

  const handleGrabarClick = (event) => {
    event.stopPropagation(); // Detener propagación del evento
    if (usuarioPerfil.length === 0) {
      console.log('No se ha seleccionado ningún perfil.');
      return;
    }
    setIsLoading(true);

    const perfilesEliminados = perfilesDesmarcados.map((perfilId) =>
      fetch(`${back}/usuariosperfiles/delusuariosperfiles`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          id_usuario: usuario.id,
          id_perfil: perfilId,
        }),
      })
    );

    Promise.all(perfilesEliminados)
      .then((responses) => {
        const allDeleted = responses.every((response) => response.ok);
        if (allDeleted) {
          fetch(`${back}/usuariosperfiles/addusuariosperfiles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
            body: JSON.stringify({
              id_usuario: usuario.id,
              id_perfil: usuarioPerfil,
            }),
          })
            .then((response) => {
              if (response.ok) {
                setIsLoading(false);
                toggleAcordeon();
                Swal.fire({
                  title: 'Información actualizada con éxito',
                  text: 'Actualización de Perfil Exitosa.',
                  icon: 'success',
                });
                console.log('Perfil actualizado correctamente.');
              } else {
                Swal.fire({
                  title: 'Error al actualizar la información',
                  text: 'Hubo un error al actualizar información de Perfil.',
                  icon: 'error',
                });
                console.error('Error al actualizar el perfil.');
              }
            })
            .catch((error) => {
              console.error('Error al enviar la solicitud al servidor:', error);
            });
        } else {
          Swal.fire({
            title: 'Error al eliminar asociaciones',
            text: 'Hubo un error al eliminar algunas asociaciones de perfil.',
            icon: 'error',
          });
          console.error('Error al eliminar algunas asociaciones de perfil.');
        }
      })
      .catch((error) => {
        console.error('Error al eliminar asociaciones:', error);
      });
  };

  const PerfilesSeleccionados = () =>
    perfiles
      .filter((perfil) => usuarioPerfil.includes(perfil.id))
      .map((perfil) => perfil.denominacion)
      .join(' - ');

  return (
    <div>
      <button id="openfilterProfiles" onClick={toggleAcordeon}>
        <h2>Perfiles: </h2>
        <section className="filterProfilesNameImg">
          <span>{PerfilesSeleccionados()}</span>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 14 14"
              style={{
                transform: abierto ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            >
              <g
                fill="none"
                stroke="#999999"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m6.46.5l6.14 6.15a.48.48 0 0 1 0 .7L6.46 13.5" />
                <path d="M1.25.5L7.4 6.65a.5.5 0 0 1 0 .7L1.25 13.5" />
              </g>
            </svg>
          </div>
        </section>
      </button>
      {abierto && (
        <div className="miDiv" onClick={(e) => e.stopPropagation()}>
          {perfiles.map((perfil) => {
            const pref = uuidv4();
            return (
              <div key={perfil.id}>
                <input
                  type="checkbox"
                  id={`cbox-${pref}-${perfil.id}`}
                  value={perfil.id}
                  checked={usuarioPerfil.includes(perfil.id)}
                  onChange={() => handleCheckboxChange(perfil.id)}
                />
                <label htmlFor={`cbox-${pref}-${perfil.id}`}>
                  {perfil.denominacion}
                </label>
                <hr />
              </div>
            );
          })}
          <button
            className="btnSaveProfile"
            onClick={handleGrabarClick}
            disabled={isLoading}
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faFloppyDisk} />
            )}
            <span>Grabar</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ItemSelect;
