import React, { useEffect, useState } from 'react';
import usuariosRoles from '../../database/usuariosRoles.json';

export const UsuariosRoles = ({ titulo }) => {
  const [lisUsuRol, setlisUsuRol] = useState([]);

  useEffect(() => {
    setlisUsuRol(usuariosRoles);
  }, []);

  const handleAddRow = () => {
    const newRow = {};
    setlisUsuRol([...lisUsuRol, newRow]);
  };
  const handleEditRow = (idUsuRol, usuario, rol) => {
    const updatedData = lisUsuRol.map((lisUsuRol) =>
      lisUsuRol.idUsuRol === idUsuRol
        ? {
            ...lisUsuRol,
            usuario: `${lisUsuRol.usuario}`,
            rol: `${lisUsuRol.rol}`
          }
        : lisUsuRol
    );
    setlisUsuRol(updatedData);
  };
  return (
    <main className="main">
      <h3>{titulo}</h3>
      <div className="search-container">
        <input
          type="text"
          id="search-input"
          className="search-input"
          placeholder="Buscar..."
        />
        <button onClick="search()" className="search-button">
          <i className="fas fa-search search-icon" />
        </button>
      </div>
      <div>
        <table className="tabla">
          <thead className="tabla__encabezado">
            <tr>
              <th className="tabla__titulo tabla__media"> Usuario </th>
              <th className="tabla__titulo tabla__media"> Roles </th>
              <th className="tabla__titulo tabla__media">
                <button
                  onClick={handleAddRow}
                  className="tabla__encabezado-btn"
                >
                  <i className="fa-regular fa-square-plus" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {lisUsuRol.map(({ index, idUsuRol, usuario, rol }) => (
              <tr key={`${index}${idUsuRol}`}>
                <td className="tabla__contenido tabla__media" key={idUsuRol}>
                  {usuario}
                </td>
                <td className="tabla__contenido tabla__media" key={idUsuRol}>
                  {rol}
                </td>
                <td className="tabla__contenido tabla__ico">
                  <button
                    className="tabla__contenido-btn"
                    onClick={() =>
                      handleEditRow(
                        lisUsuRol.id,
                        lisUsuRol.usuario,
                        lisUsuRol.rol
                      )
                    }
                  >
                    <i
                      className="fa-solid fa-file-pen"
                      style={{ color: '#808080' }}
                    />
                  </button>
                  <button className="tabla__contenido-btn">
                    <i
                      className="fas fa-trash-alt delete-icon"
                      style={{ color: '#808080' }}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};
