import { useState, useEffect, useContext } from "react";
import { API_PURCHASE_OK } from "../services/ApiRoutes";
import { UserContext } from "../../../auth/context/UserContext";

export const useGetPurchaseOk = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { dataUser } = useContext(UserContext);
  const { token, id } = dataUser;

  useEffect(() => {
    setLoading(true);
    const endpoint = `${API_PURCHASE_OK}?userId=${id}`;
    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        setPedidos(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      });
  }, [token, id]);

  return { pedidos, loading, error };
};
